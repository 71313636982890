<template>
  <div>
    <p class="label-20 averta-black mb-5">
      {{ typeSidebar == 'edit' ? 'Edit Client' : 'Buat Client Baru' }}
    </p>
    <div class="border-2 border-radius-20 overflow-hidden p-5 bg-grey-15">
      <p class="label-20 averta-black">Informasi Perusahaan</p>
      <p>Silakan masukkan informasi perusahaan dibawah yang ingin didaftarkan</p>
      <div class="mt-5">
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Nama PT <span class="color-red">*</span></p>
          </div>
          <b-input
            v-model="formClient.company_name"
            placeholder="Masukkan nama PT"
            class="input-search-2 c-w-85 input-none"
          ></b-input>
        </div>
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Nama Brand <span class="color-red">*</span></p>
          </div>
          <b-input
            v-model="formClient.name"
            placeholder="Masukkan nama brand"
            class="input-search-2 c-w-85 input-none"
          ></b-input>
        </div>
        <div class="flex-center-vertical mb-4">
          <div class="input-label">
            <p>Logo <span class="color-red">*</span></p>
          </div>
          <div class="flex-center-vertical">
            <label for="input-file" class="flex-center-vertical">
              <div class="img-kanggox mr-3">
                <img :src="require('@/assets/img/kanggox.png')" alt="" />
              </div>
              <div v-if="formClient.logo" class="is-relative">
                <div class="preview-crop-img is-clickable">
                  <img :src="formClient.logo" alt="" />
                </div>
                <div class="edit-image">
                  <EditIcon />
                </div>
              </div>
              <div v-else class="flex-center-vertical is-clickable input-search-1 bg-grey-3">
                <UploadLightIcon />
                <p class="ml-1">Upload Foto</p>
              </div>
            </label>
            <input
              type="file"
              id="input-file"
              class="d-none"
              ref="clientImg"
              @change="uploadImageFile"
              hidden
              accept="image/png, image/jpeg"
            />
          </div>
        </div>

        <div class="flex-center-vertical mb-4 c-w-100">
          <div class="input-label">
            <p>Sesi Survey <span class="color-red">*</span></p>
          </div>
          <b-dropdown
          expanded
          class="c-w-85"
          scrollable
          max-height="365px"
          append-to-body>
              <template #trigger>
                <b-input
                  v-model="selectedSurveyName"
                  placeholder="Pilih sesi survey yang dapat dipilih oleh client ini"
                  class="input-search-2 input-none c-w-100"
                  readonly
                  >
                </b-input>
              </template>

              <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start p-5 gap-6">
                <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100">
                  <p class="averta-bold">Daftar Sesi Survey</p>
                  <button v-if="selectedSurveyName" class="button-unselect" @click="clearSelectedSurvey">
                    <p>Clear Select</p>
                  </button>
                </div>

                <div v-for="(listDataSurvey, listDataSurveyIndex) in getDataListSurvey" :key="listDataSurveyIndex" class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start gap-5">
                  <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                    <b-field>
                      <b-radio
                        v-model="selectedSurveyId"
                        :native-value="listDataSurvey.id"
                        @input="selectSurvey(listDataSurvey)"
                      >
                        <p class="averta-bold" type="is-danger">{{ listDataSurvey.name }}</p>
                      </b-radio>
                     </b-field>
                  </div>
                  <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3">
                    <b-button
                    v-for="(session, buttonIndex) in listDataSurvey.sessions"
                    :key="buttonIndex"
                    size="is-small"
                    class="button-select-sesi"
                    :class="{ 'is-selected': selectedSurveyId === listDataSurvey.id }"
                    >
                      {{ session.name }}
                    </b-button>
                  </div>
                </div>

              </div>
          </b-dropdown>
        </div>

        <div class="flex-center-vertical mb-4 c-w-100">
          <div class="input-label">
            <p>Sesi Pekerjaan <span class="color-red">*</span></p>
          </div>
          <b-dropdown
          expanded
          class="c-w-85"
          scrollable
          max-height="365px"
          append-to-body>
              <template #trigger>
                <b-input
                  v-model="selectedProjectByName"
                  placeholder="Pilih sesi proyek yang dapat dipilih oleh client ini"
                  class="input-search-2 input-none c-w-100"
                  readonly
                  >
                </b-input>
              </template>

              <div class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start p-5 gap-6">
                <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-100">
                  <p class="averta-bold">Daftar Sesi Pekerjaan</p>
                  <button v-if="selectedProjectByName" class="button-unselect" @click="handlerUnselectAllProject">
                    <p>Clear Select</p>
                  </button>
                </div>

                <div v-for="(listDataProject, listDataProjectIndex) in getDataListProject" :key="listDataProjectIndex" class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start gap-5">
                  <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-4">
                    <b-checkbox
                     v-model="listDataProject.selected"
                     @input="toggleAllSessionsProject(listDataProject)"
                     type="is-danger"
                    >
                      <p class="averta-bold">{{ listDataProject.name }}</p>
                    </b-checkbox>
                    <b-switch
                      v-if="isProjectActive(listDataProject)"
                      :value="isMainSessionProject(listDataProject)"
                      @input="toggleMainSessionProject(listDataProject)"
                      type="is-info"
                    >
                      {{ isMainSessionProject(listDataProject) ? 'Sesi Utama' : 'Jadikan Sesi Utama' }}
                    </b-switch>
                  </div>
                  <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center gap-3">
                    <b-button
                      v-for="(session, buttonIndex) in listDataProject.sessions"
                      :key="buttonIndex"
                      size="is-small"
                      class="button-select-sesi"
                      :class="{ 'is-selected': session.selected }"
                    >
                      {{ session.name }}
                    </b-button>
                  </div>
                </div>
              </div>
          </b-dropdown>
        </div>

        <ModalCropImage
          :showModal="showModalCropImage"
          :previewImage="preview_crop"
          :closeModal="closeModalCropImage"
          @cropImage="cropImage($event)"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isObjectsSimilar } from '@/helpers'
import { UploadLightIcon, EditIcon } from '@/components/icons'
import ModalCropImage from '../client-components/ModalCropImage.vue'

export default {
  name: 'ContentAddEditClient',
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    },
    changeTypeContent: {
      type: Function
    },
    getDataListSurvey: {
      type: Array,
      default: () => []
    },
    getDataListProject: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UploadLightIcon,
    EditIcon,
    ModalCropImage
  },
  data () {
    return {
      typeSidebar: this.getTypeSidebar,
      showModalCropImage: false,
      initialFormCompare: null,

      preview_crop: null,
      formClient: {
        company_name: '',
        name: '',
        logo: '',
        imgFile: null,
        getSelectDataIdSurvey: '',
        getSelectDataIdProject: '',
        mainSessionIdProject: ''
      },
      selectedDataSurvey: [],
      selectedDataProject: [],
      mainSessionIdProject: null,
      selectedSurveyId: null,
      selectedSurveyName: ''
    }
  },
  computed: {
    ...mapGetters({
      getPayloadClient: 'client/getPayloadClient',
      getPayloadDataFormClient: 'client/getPayloadDataFormClient',
      getTypeSidebar: 'client/getClientTypeSidebar',
      getShowSidebar: 'client/getClientShowSidebar'
    }),
    selectedProjectByName () {
      return this.getDataListProject
        .filter(survey => survey.selected)
        .map(survey => survey.name)
        .join(', ')
    }
  },
  methods: {
    closeModalCropImage () {
      this.showModalCropImage = false
    },
    cropImage (result) {
      const { preview, file } = result

      this.formClient.logo = preview
      this.formClient.imgFile = file
      this.showModalCropImage = false
    },
    uploadImageFile (e) {
      e.preventDefault()
      if (e.target.files[0]) {
        this.preview_crop = URL.createObjectURL(e.target.files[0])
        this.showModalCropImage = true
        this.$refs.clientImg.value = ''
      }
    },
    initialForm (data) {
      // eslint-disable-next-line camelcase
      const { company_name = '', name, logo, imgFile, id, getSelectDataIdSurvey, getSelectDataIdProject, mainSessionIdProject } = data

      this.formClient = {
        company_name,
        name,
        logo,
        imgFile,
        id,
        getSelectDataIdSurvey,
        getSelectDataIdProject,
        mainSessionIdProject
      }
      console.log(data)
      // this.preview_image = logo
    },
    handlerUnselectAllProject () {
      this.getDataListProject.forEach(project => {
        project.selected = false
        project.sessions.forEach(session => {
          session.selected = false
        })
      })
    },
    handleResetForm () {
      this.preview_crop = null
      this.formClient = {
        company_name: '',
        name: '',
        logo: '',
        imgFile: null
      }
      this.clearSelectedSurvey()
      this.handlerUnselectAllProject()
    },
    selectSurvey (survey) {
      this.selectedSurveyId = survey.id
      this.selectedSurveyName = survey.name
      this.getSelectDataIdSurvey = this.selectedSurveyId
      this.formClient.getSelectDataIdSurvey = this.selectedSurveyId
      this.updateSelectedDataSurvey()
    },

    clearSelectedSurvey () {
      this.selectedSurveyId = null
      this.selectedSurveyName = ''
      this.formClient.getSelectDataIdSurvey = []
      this.updateSelectedDataSurvey()
    },

    updateSelectedDataSurvey () {
      const selectedSurvey = this.getDataListSurvey.find(survey => survey.id === this.selectedSurveyId)
      if (selectedSurvey) {
        this.selectedDataSurvey = [{
          id: selectedSurvey.id,
          name: selectedSurvey.name,
          sessions: selectedSurvey.sessions.map(session => ({
            name: session.name,
            start_time: session.start_time,
            end_time: session.end_time
          }))
        }]
      } else {
        this.selectedDataSurvey = []
      }
    },

    toggleAllSessionsProject (project) {
      project.sessions.forEach(session => {
        session.selected = project.selected
      })
      this.updateSelectedDataProject()

      if (project.selected && this.mainSessionIdProject === null) {
        this.activateMainSessionProject(project)
      } else if (!project.selected && this.mainSessionIdProject === project.id) {
        this.mainSessionIdProject = null
      }
    },
    updateSelectedDataProject () {
      this.selectedDataProject = this.getDataListProject
        .filter(project => project.selected || project.sessions.some(session => session.selected))
        .map(project => ({
          id: project.id,
          name: project.name,
          sessions: project.sessions
            .filter(session => session.selected)
            .map(session => ({
              name: session.name,
              start_time: session.start_time,
              end_time: session.end_time
            }))
        }))
      this.getSelectDataIdProject = this.selectedDataProject.map(data => data.id)
      this.formClient.getSelectDataIdProject = this.getSelectDataIdProject
    },
    isProjectActive (project) {
      return project.selected || project.sessions.some(session => session.selected)
    },
    isMainSessionProject (project) {
      this.formClient.mainSessionIdProject = this.mainSessionIdProject
      return this.mainSessionIdProject === project.id
    },
    toggleMainSessionProject (project) {
      if (this.mainSessionIdProject === project.id) {
        this.mainSessionIdProject = null
      } else {
        this.mainSessionIdProject = project.id
      }
    },
    activateMainSessionProject (project) {
      this.mainSessionIdProject = project.id
    }
  },
  watch: {
    formClient: {
      handler (value) {
      // eslint-disable-next-line camelcase
        const { company_name, name, logo, imgFile, id, getSelectDataIdProject, getSelectDataIdSurvey, mainSessionIdProject } = value
        const body = {
          company_name,
          name,
          logo,
          imgFile,
          id,
          getSelectDataIdProject,
          getSelectDataIdSurvey,
          mainSessionIdProject

        }
        const isEmptyForm = Object.values(body).some(item => item === null || item === '')

        let isSimilar = false
        if (this.getTypeSidebar === 'edit') {
          isSimilar = isObjectsSimilar(this.initialFormCompare, value)
        }
        this.$store.commit('client/setPayloadClient', { dataForm: body, isEmptyForm: isEmptyForm || isSimilar })
      },
      deep: true
    },
    getTypeSidebar (value) {
      this.typeSidebar = value
    },
    getShowSidebar (value) {
      if (value) {
        this.initialForm(this.getPayloadDataFormClient)
        this.initialFormCompare = JSON.parse(JSON.stringify(this.getPayloadDataFormClient))
      } else {
        this.handleResetForm()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.input-label {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.img-kanggox, .preview-crop-img {
  height: 40px;
}

.img-kanggox img, .preview-crop-img img {
  height: 100%;
  object-fit: contain;
}

.edit-image {
  position: absolute;
  right: -15px;
  top: 20px;
}

.is-selected {
  background-color: #FCE5E6 !important; /* Warna biru */
  color: #2F2F2F !important;
}

.button-select-sesi {
  border-radius: 10px !important;
  background: #F5F5F5;
  color: #2F2F2F !important;
  width: 125px;
  height: 35px;
}

.button-unselect {
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  border-radius: 5px;
  background: #E10009;
  color: #ffffff !important;
  border: none !important;
  font-weight: 700;
  margin-left:  auto;
  cursor: pointer !important;
}
</style>
