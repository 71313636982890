<template>
  <b-sidebar
    type="is-light"
    :fullheight="true"
    :fullwidth="true"
    :overlay="true"
    :right="true"
    :can-cancel="false"
    v-model="showSidebar"
    class="sidebar-preview"
  >
    <div class="content-sidebar">
      <div class="sidebar-header navbar is-fixed-top px-4 py-2">
        <img :src="require('@/assets/img/logo-kanggo-2.png')" alt="" class="img-kanggo">
      </div>
      <div class="body-sidebar overflow-auto">
        <slot name="bodySidebar"></slot>
      </div>
      <div class="footer-sidebar px-4 py-2 is-flex is-justify-content-flex-end is-align-items-center">
        <ButtonApp height="40px" :isSecondary="true" @click="closeSidebar">
          <p class="averta-bold px-4">Batalkan</p>
        </ButtonApp>
        <ButtonApp height="40px" :isDisabled="disabledSubmit" @click="submitSidebar" class="ml-4">
          <p class="averta-bold px-4">Simpan</p>
        </ButtonApp>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'SidebarClientTemplate',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    disabledSubmit: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ButtonApp
  },
  data () {
    return {
      showSidebar: false,
      widthSidebar: this.width
    }
  },
  methods: {
    closeSidebar () {
      this.$emit('closeSidebar')
    },
    submitSidebar () {
      if (this.disabledSubmit) return
      this.$emit('submit')
    }
  },
  watch: {
    show (newValue) {
      this.showSidebar = newValue
    }
  }
}
</script>

<style scoped>
.sidebar-preview {
  background-color: #fff
}

.sidebar-header {
  border-bottom: 1px solid #E1E1E1
}

.content-sidebar {
  width: 100%;
  height: 100vh;
  position: relative;
  font-family: Averta;
}

.body-sidebar {
  padding: 90px 30px 30px 30px;
  height: 91%;
}

.img-kanggo {
  width: 10%;
  object-fit: contain;
}

.footer-sidebar {
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0px -3px 30px 0px #00000012;
  width: 100%;
  height: 9%;
}
</style>
