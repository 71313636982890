export function updatedCards (cards, initConsultants) {
  const tempArr = initConsultants || []
  for (const item of cards) {
    const newId = randomConsultantId()
    const body = {
      consultant_id: newId,
      list: [{ ...item, consultant_id: newId, removed: false }]
    }
    if (tempArr.length < 1) {
      tempArr.push(body)
      for (let i = 0; i < 3; i++) {
        const additional = {
          consultant_id: randomConsultantId(),
          is_additional: true,
          list: []
        }
        tempArr.push(additional)
      }
    } else {
      let isOverlap = false
      for (const [index, temp] of tempArr.entries()) {
        const overlapping = temp.list.find(e => {
          return (e.start_time >= item.start_time && e.start_time < item.end_time) ||
                (e.end_time > item.start_time && e.end_time <= item.end_time) ||
                (e.start_time <= item.start_time && e.end_time >= item.end_time)
        })
        if (overlapping) {
          isOverlap = true
        } else {
          isOverlap = false
          tempArr[index].list.push({ ...item, consultant_id: temp.consultant_id })
          break
        }
      }
      if (isOverlap) {
        tempArr.push(body)
        const findAdditional = tempArr.filter(e => e.is_additional)
        if (findAdditional && findAdditional.length < 3) {
          for (let i = 0; i < 3; i++) {
            const additional = {
              consultant_id: randomConsultantId(),
              is_additional: true,
              list: []
            }
            tempArr.push(additional)
          }
        }
      }
    }
  }

  const result = {
    consultants: tempArr.map(item => ({ consultant_id: item.consultant_id })),
    cards: tempArr.flatMap(item => item.list)
  }
  return result
}

export function randomConsultantId () {
  return `CONS_${Math.floor(5000 + Math.random() * 2000)}`
}
