<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-sidebar
    type="is-light"
    :fullheight="fullheight"
    :fullwidth="fullwidth"
    :overlay="overlay"
    :right="right"
    v-model="isHandleOpenSidebarKuotaSesiPengerjaan"
  >
    <div
      class="is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start c-w-100"
    >
      <div class="container-unit-price min-h-90vh c-w-100">
        <div class="c-w-100 py-5 px-6">
          <div class="is-flex is-justify-content-space-between mb-5">
            <div class="is-flex is-align-items-center is-clickable" @click="closeSidebar()">
              <ArrowLeftIcon class="mr-3" />
              <p class="averta-bold is-size-5">Kembali</p>
            </div>
            <div>
              <ButtonApp :isDisabled="true">
                <div class="flex-center pr-1 py-2">
                  <p class="averta-bold mr-3">Simpan</p>
                  <ArrowRightIconLong color="#fff" />
                </div>
              </ButtonApp>
            </div>
          </div>
          <div>
            <p class="averta-black label-18">
              Masukan Nama Item Pekerjaan <span class="color-red">*</span>
            </p>
            <p class="color-grey mb-3">Pastikan nama yang dimasukan unik dan jelas</p>
            <textarea
              rows="6"
              class="mt-1 textarea style-textarea-wrapper label-14"
              placeholder="Isi disini"
            ></textarea>
          </div>
        </div>
        <div class="divider-bold"></div>
        <div class="c-w-100 py-5 px-6 is-flex is-flex-direction-column gap-3">
          <p class="averta-black label-18 mb-4">Detail Informasi Item pekerjaan</p>
          <div
            class="is-flex is-flex-direction-column bg-lightgray border-radius-12 overflow-visible p-4 gap-5"
          >
            <p class="averta-black label-18 mb-2">Kuota Sesi Pengerjaan</p>
            <div class="input-wrapper-grid">
              <div>
                <p class="mb-2">
                  Volume Maksimal (Untuk 8 Jam Pengerjaan)
                  <span class="color-grey averta-reguler-italic">(Opsional)</span>
                </p>
                <InputComponents :type="'number'" :placeholder="0" :disabled="true" />
              </div>
              <div class="is-flex is-flex-direction-column">
                <p class="mb-2">Satuan <span class="color-red">*</span></p>
                <InputAutocompleteSatuan
                />
              </div>
              <div>
                <p class="mb-2">
                  Kuota Sesi Pengerjaan
                  <span class="color-grey averta-reguler-italic">(Opsional)</span>
                </p>
                <InputComponents :type="'number'" :placeholder="0" :textBold="true" :disabled="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { ArrowRightIconLong, ArrowLeftIcon } from '@/components/icons'
import ButtonApp from '@/components/button/ButtonApp.vue'

export default {
  name: 'SidebarKuotaSesiPengerjaan',
  props: {
    isHandleOpenSidebarKuotaSesiPengerjaan: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ArrowRightIconLong,
    ArrowLeftIcon,
    ButtonApp,
    InputAutocompleteSatuan: () => import('@/components/input/InputAutocompleteSatuan.vue'),
    InputComponents: () => import('@/components/input/InputComponents.vue')
  },
  data () {
    return {
      overlay: true,
      fullheight: true,
      fullwidth: true,
      right: true
    }
  },
  methods: {
    closeSidebar () {
      this.$emit('closeSidebar')
    }
  }
}
</script>

<style scoped>
.input-wrapper-grid {
  display: grid;
  column-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}
</style>
