<template>
  <div class="c-w-shell min-h-80vh is-flex">
    <div class="sidebar-monitoring" :class="{ 'minimize-sidebar': minimize }">
      <div class="flex-center-vertical is-justify-content-space-between p-4">
        <div class="flex-center is-clickable">
          <ArrowLeftIcon color="#868686" />
          <p class="averta-bold color-grey ml-2 label-16">Kembali</p>
        </div>
        <img
          class="is-clickable minimize-icon"
          :src="require('@/assets/icons/minimize-icon-used.svg')"
          alt=""
          @click="handleMinimizeSidebar"
        />
      </div>

      <div class="v-calendar-wrapper-normal border-bottom-1 p-2">
        <v-date-picker v-model="selectedDate" :from-page="fromPage" is-expanded color="red" />
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Cari Kata Kunci</p>
        <b-input
          placeholder="ID Survey"
          type="search"
          icon="magnify"
          class="search-left-monitoring"
          v-model="search"
          @keyup.native.enter="filteredData"
        >
        </b-input>
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Filter Konsultan Survey</p>
        <b-autocomplete
          v-model="keywordConsultant"
          placeholder="Semua Konsultan Survey"
          class="search-left-monitoring"
          :open-on-focus="true"
          :data="filteredKonsultan"
          field="name"
          @select="(option) => (selectedConsultant = option)"
          :clearable="true"
        />
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Filter Client</p>
        <b-autocomplete
          v-model="client"
          placeholder="Semua Client"
          class="search-left-monitoring client"
          :open-on-focus="true"
          :data="filteredClient"
          field="name"
          @select="(option) => (selectedClient = option)"
          :clearable="true"
        />
      </div>
    </div>
    <div class="body-monitoring" ref="mainBody" :class="{ 'filled-monitoring': minimize }">
      <div class="header-current-date">
        <div class="flex-center">
          <div class="date-minimize-icon flex-center" :class="{ 'expand-minimize-icon': minimize }">
            <img
              class="is-clickable"
              :src="require('@/assets/icons/minimize-icon-used.svg')"
              alt=""
              @click="handleMinimizeSidebar"
            />
          </div>
          <p class="averta-black label-20">{{ formatSelectedDate }}</p>
        </div>
        <div class="flex-center">
          <ButtonApp :isSecondary="true" class="mr-2" @click="openGuideSesi">
            <p class="p-1 averta-bold label-12">Panduan Sesi</p>
          </ButtonApp>
          <div class="previous-next-arrow mr-1 flex-center" @click="() => handlePrevNext('prev')">
            <img :src="require('@/assets/icons/arrow-back-used.svg')" alt="" />
          </div>
          <div class="previous-next-arrow flex-center" @click="() => handlePrevNext('next')">
            <img :src="require('@/assets/icons/arrow-forward-used.svg')" alt="" />
          </div>
        </div>
      </div>

      <div
        class="collapse-sesi-custom-wrapper"
        :class="{ 'minimize-sidebar': minimize, 'removed-wrapper-sesi-custom': sesiCustom.length === 1 && sesiCustom[0].removed }"
        v-if="sesiCustom.length > 0"
      >
        <b-collapse
          class="collapse-sesi-custom"
          animation="slide"
          v-model="openCollapse"
          aria-id="sesi-custom"
        >
          <template #trigger="props">
            <div
              class="flex-center-vertical is-justify-content-space-between c-w-100"
              aria-controls="sesi-custom"
              :aria-expanded="props.open"
            >
              <div class="flex-center">
                <p class="averta-black label-15">
                  {{ sesiCustom.length }} Survey Custom
                  <span class="averta-reguler label-14">yang belum diatur jadwalnya</span>
                </p>
              </div>
              <ButtonApp :isSecondary="true" :isTransparent="true" :isBorderWhite="true">
                <div class="is-flex is-align-items-center py-1">
                  <p class="averta-bold mr-2 color-white">
                    {{ openCollapse ? 'Tutup Daftar' : 'Lihat Daftar' }}
                  </p>
                  <div class="chevron-sesi-custom" :class="{ open: !openCollapse }">
                    <ChevronIcon color="white" />
                  </div>
                </div>
              </ButtonApp>
            </div>
          </template>
          <div>
            <div class="svg-border">
              <svg width="100%" height="10">
                <line x1="0" y1="5" x2="100%" y2="5" stroke="white" stroke-width="2" stroke-dasharray="10,5" />
              </svg>
            </div>
            <div class="body-sesi-custom color-black">
              <p class="averta-bold mb-2">Daftar Survey</p>
              <div class="list-survey-custom-wrapper">
                <div
                  v-for="(item, index) in sesiCustom"
                  :key="`sesi-custom=${index}`"
                  class="list-sesi-custom"
                  :class="{'removed-sesi-custom': item.removed}"
                  :draggable="true"
                  @dragstart="onDragSesiCustomStart($event, item)"
                  @dragend="onDragSesiCustomEnd"
                >
                  <div class="flex-center-vertical info-vendor-sesi-custom">
                    <div class="vendor-img-sesi-custom flex-center">
                      <img :src="item.vendor_logo" alt="" />
                    </div>
                    <p class="averta-bold color-red">
                      {{ item.vendor_id }} | {{ item.vendor_name }}
                      {{ item.no_unit ? '-' + item.no_unit : '' }}
                    </p>
                  </div>
                  <div class="address-sesi-custom">
                    <div>
                      <p class="color-grey">Alamat</p>
                      <p>{{ item.address }}</p>
                    </div>
                  </div>
                  <div class="drag-icon-sesi-custom flex-center">
                    <img
                      class="drag-icon"
                      :src="require('@/assets/icons/fluent-drag-black-used.svg')"
                      alt=""
                      style="pointer-events: none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <div class="schedule-table" :class="{ 'loading-table': isLoadingTable }">
        <!-- Header -->
        <div
          class="header-table"
          :style="{
            top: positionStickyHeader
          }"
        >
          <div
            class="cell header-empty sticky-left"
            :style="{ height: `${HEIGHT_COLUMN}px` }"
          ></div>
          <div
            class="cell header"
            :style="{ height: `${HEIGHT_COLUMN}px` }"
            v-for="(item, index) in consultants"
            :key="`consultan-${index}`"
          >
            <div v-if="item.empty"></div>
            <div
              v-else
              class="flex-center c-w-100 pr-1"
              :class="{ 'is-clickable': !item.name }"
              @click="() => handleSelectConsultant(item)"
            >
              <div class="consultan-img flex-center mr-2 flex-shrink-0">
                <img
                  :src="
                    item.image && item.image !== '-'
                      ? item.image
                      : require('@/assets/icons/profile-picture-used.svg')
                  "
                  alt=""
                />
              </div>
              <div class="has-text-left ellipsis-content flex-grow-0">
                <p
                  class="label-14"
                  :class="[
                    item.name ? 'averta-black' : 'averta-reguler-italic',
                    { 'color-red': !item.name }
                  ]"
                >
                  {{ item.name ? item.name : 'Pilih Konsultan' }}
                </p>
                <p v-if="item.name" class="color-red averta-bold label-12">{{ item.id }}</p>
                <p v-if="item.vendor && item.vendor.vendor_name" class="label-12">
                  {{ item.vendor.vendor_name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="body">
          <div
            class="d-contents is-flex is-relative"
            v-for="(time, idxTime) in timeSurvey"
            :key="`time-${idxTime}`"
          >
            <div
              class="cell time"
              :style="{
                zIndex: 22,
                height: `${HEIGHT_COLUMN}px`
              }"
            >
              <div v-if="time !== 'empty'" class="time-survey flex-center">
                <p class="averta-black">{{ time }}</p>
                <div class="line"></div>
              </div>
            </div>
            <div
              v-for="(consultant, idxConsultant) in consultants"
              :key="`card-consultant-${idxConsultant}`"
              class="cell body is-relative"
              :class="{'bg-grey-3': consultant.empty}"
              :style="{ height: `${HEIGHT_COLUMN}px` }"
              :data-timeslot="time"
              :data-consultant="consultant.id"
              @drop="onDrop($event, time, consultant)"
              @dragenter.prevent="onDragEnter($event, time, consultant.id)"
              @dragleave.prevent="onDragLeave($event)"
              @dragover.prevent
            >
              <div
                v-for="(item, idxCard) in getCardsForSlot(time, consultant.id)"
                :key="`card-events-${idxCard}`"
                class="card-events"
                :class="{
                  'card-sesi-custom': item.sesi === 'custom',
                  'cursor-move': cursorMove,
                  'z-index-300 border-white-1': (idHoverCard === item.id || idHoverDragIcon === item.id) && !isStretching && !modalActionConsultant,
                  'remove-card': tempCard && tempCard.removed && tempCard.id === item.id,
                  'transparent-card': consultantIdHoverCard === consultant.id && idHoverCard !== item.id && !isStretching,
                  'opacity-effect': consultant.is_assigned
                }"
                :style="{
                  height: getCardStyle(item, idxTime),
                  backgroundColor: item.background
                }"
                :ref="`card-${item.id}`"
                :draggable="cursorMove ? true : false"
                @dragstart="onDragStart($event, item, consultant)"
                @dragend="onDragEnd"
                @mousemove="checkIfReaching"
                @mouseleave="resetReaching"
              >
                <div
                  class="drag-icon-wrapper"
                  @mouseover="() => handleHoverDragIcon(item.id)"
                  @mouseleave="() => handleHoverDragIcon(null)"
                >
                  <img
                    class="mb-3 drag-icon"
                    :src="require('@/assets/icons/fluent-drag-used.svg')"
                    alt=""
                    style="pointer-events: none"
                  />
                </div>
                <div
                  class="body-card-events"
                  @mouseover="() => handleHoverCard(item.id, consultant.id)"
                  @mouseleave="() => handleHoverCard(null)"
                >
                  <div class="flex-center is-flex-direction-column c-h-100 overflow-auto">
                    <div class="max-c-h-100 c-w-100 px-2 description-card">
                      <div v-if="item.branch_logo || item.vendor_logo" class="vendor-card-img">
                        <img :src="item.branch_logo || item.vendor_logo" alt="" />
                      </div>
                      <p>ID: {{ item.survey_id }}</p>
                      <p class="averta-bold">{{ item.branch_name }}</p>
                      <p>{{ item.sesi }} {{ item.start_time }} - {{ item.end_time }}</p>
                    </div>
                  </div>

                  <!-- TOOLTIP -->
                  <div
                    @mouseover="() => handleHoverCard(item.id, consultant.id)"
                    @mouseleave="() => handleHoverCard(null)"
                    class="tooltip-hover-card color-black"
                    :class="{ visible: idHoverCard === item.id && !isStretching }"
                  >
                    <div class="tooltip-header flex-center-vertical">
                      <div class="tooltip-vendor-img">
                        <img :src="item.vendor_logo" alt="" />
                      </div>
                      <div class="ellipsis-content flex-grow-0">
                        <p class="averta-black label-16">
                          {{ item.vendor_id }} | {{ item.branch_name }}
                        </p>
                        <p>{{ item.vendor_name }}</p>
                      </div>
                    </div>
                    <div class="p-4">
                      <div class="tooltip-date flex-center is-flex-direction-column">
                        <p class="color-blue-1 label-12">Tanggal & Waktu Survey</p>
                        <p class="averta-bold">{{ formatDate(item.start_date) }}</p>
                        <p class="color-grey">
                          ({{ item.sesi ? item.sesi + ': ' : '' }}{{ item.start_time }} - {{ item.end_time }})
                        </p>
                      </div>
                    </div>
                    <div class="is-flex px-4 pb-3">
                      <ButtonApp
                        width="100%"
                        :isSecondary="true"
                        class="mr-3"
                        @click="() => handleSecondaryTooltip(item, consultant)"
                      >
                        <p class="py-2 averta-bold">{{typeof item.consultant_id === 'string' && item.consultant_id.includes('CONS') ? 'Atur Konsultan' : 'Hapus Konsultan'}}</p>
                      </ButtonApp>
                      <ButtonApp
                        width="100%"
                        @click="() => handleDetailConsultant(item.id)"
                      >
                        <p class="py-2 averta-bold">Lihat Detail</p>
                      </ButtonApp>
                    </div>
                  </div>

                  <!-- EXPANDING CARD -->
                  <div
                    v-if="item.sesi === 'custom'"
                    class="expand-handle"
                    @mousedown="($event) => startStretching($event, item)"
                  ></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Action Consultant  -->
      <b-modal v-model="modalActionConsultant" @close="handleCancelModalAction">
        <div class="flex-center">
          <div class="modal-delete-consultant">
            <div class="flex-center is-flex-direction-column c-w-100 mb-5 has-text-centered">
              <img :src="require('@/assets/img/warning-1.png')" alt="" />
              <p class="averta-black color-red label-16 mt-3">{{ titleModalAction }}</p>
              <p :style="{ width: widthDescModalAction }">{{ descModalAction }}</p>
            </div>
            <div v-if="tempCard" class="bg-lightgray border-radius-12 p-3">
              <div class="border-bottom-dashed flex-center-vertical pb-3 mb-3">
                <div class="modal-vendor-icon flex-center mr-2">
                  <img :src="tempCard.vendor_logo" alt="" />
                </div>
                <p class="averta-black color-red label-13">
                  {{ tempCard.vendor_id }} | {{ tempCard.vendor_name }}
                </p>
              </div>
              <div class="is-flex">
                <div class="flex-1 border-right-1-grey-13">
                  <p class="color-grey mb-1">Jadwal Survey</p>
                  <p class="averta-bold label-13">{{ formatDate(tempCard.start_date) }}</p>
                  <p class="label-12">
                    {{ tempCard.sesi ? tempCard.sesi + ': ' : '' }} {{ tempCard.start_time }} - {{ tempCard.end_time }}
                  </p>
                </div>
                <div class="flex-1 ml-3">
                  <p class="color-grey mb-1">Konsultan Survey</p>
                  <div class="is-flex is-justify-content-space-between">
                    <div class="flex-center-vertical flex-1">
                      <div class="modal-consultant-img mr-2">
                        <img :src="tempCard.consultant.image && tempCard.consultant.image !== '-' ? tempCard.consultant.image : require('@/assets/icons/profile-picture-used.svg')" alt="" />
                      </div>
                      <div>
                        <p class="averta-bold label-13 text-ellipsis-2-line">{{ tempCard.consultant.name || '-' }}</p>
                        <p v-if="tempCard.consultant.name" class="color-red label-12">{{ tempCard.consultant.id }}</p>
                      </div>
                    </div>
                    <div class="flex-1" v-if="tempCard.consultant.vendor">
                      <p class="label-12 ml-2 text-ellipsis-2-line">
                        {{ tempCard.consultant.vendor && tempCard.consultant.vendor.vendor_name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="is-flex mt-4">
                <ButtonApp
                  width="100%"
                  :isSecondary="true"
                  class="mr-3"
                  @click="handleCancelModalAction"
                >
                  <p class="py-2 averta-bold">Cek Lagi</p>
                </ButtonApp>
                <ButtonApp width="100%" @click="handleConfirmModalAction">
                  <p class="py-2 averta-bold">{{ btnModalAction }}</p>
                </ButtonApp>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <ModalSelectConsultant
        :type="typeModalConsultant"
        :consultants="listSearchConsultant"
        :excluedConsultants="initConsultants"
        :open="modalSelectConsultant"
        @closeModal="closeModalSelectConsultant"
        @selected="onSelectConsultant"
      />

      <ModalGuideSesi
        :open="modalGuide"
        @closeModal="openGuideSesi"
      />

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { alertErrorMessage, toastMessage, randomTerseHexColor } from '@/helpers'
// eslint-disable-next-line no-unused-vars
import { updatedCards, randomConsultantId } from '@/helpers/monitoring'

export default {
  name: 'MonitoringKonsultan',
  components: {
    ArrowLeftIcon: () => import('@/components/icons/ArrowLeftIcon.vue'),
    ChevronIcon: () => import('@/components/icons/ChevronIcon.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    ModalSelectConsultant: () => import('../components/ModalSelectConsultant.vue'),
    ModalGuideSesi: () => import('../components/ModalGuideSesi.vue'),
    SpinnerIcon: () => import('@/components/icons/SpinnerIcon')
  },
  data () {
    return {
      isLoading: false,
      isLoadingClient: false,
      isLoadingKonsultan: false,
      isLoadingTable: false,

      minimize: false,
      selectedDate: new Date(),
      search: '',
      keywordConsultant: '',
      client: '',

      cursorMove: false,
      idHoverDragIcon: null,
      idHoverCard: null,
      tempIdHoverCard: null,
      consultantIdHoverCard: null,
      tempConsultantIdHoverCard: null,
      fromPage: {
        year: parseInt(moment(new Date()).format('YYYY')),
        month: parseInt(moment(new Date()).format('M'))
      },

      modalActionConsultant: false,
      modalSelectConsultant: false,
      modalGuide: false,
      tempCard: null,
      tempDataConsultant: null,
      dataFloating: null,
      openCollapse: false,

      selectedConsultant: null,
      selectedClient: null,
      listSearchConsultant: [],
      dataClients: [],
      initDataClients: [],

      timeSurvey: [],
      consultants: [],
      cardSurvey: [],
      initConsultants: [],
      initCardSurvey: [],
      sesiCustom: [],
      draggedEvent: null,
      tempDraggedEvent: null,
      typeModalAction: '',
      typeModalConsultant: '',

      dataStretching: null,
      isStretching: false,
      startMouseY: 0,
      rowHeight: 80,
      cardHeight: 80,
      initialHeight: 80,

      HEIGHT_COLUMN: 80,
      WIDTH_COLUMN: 200
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    filteredKonsultan () {
      return this.listSearchConsultant.filter((option) => {
        return option.name && option.name.toString().toLowerCase().indexOf(this.keywordConsultant.toLowerCase()) >= 0
      })
    },
    filteredClient () {
      return this.dataClients.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.client.toLowerCase()) >= 0
      })
    },
    formatSelectedDate () {
      return moment(this.selectedDate).format('dddd, DD MMMM YYYY')
    },
    positionStickyHeader () {
      if (this.sesiCustom.length === 0) return '70px'
      if (!this.openCollapse) return '168px'
      if (this.sesiCustom.length === 1 && this.openCollapse) return '350px'
      if (this.sesiCustom.length > 1) return '432px'
      return '70px'
    },
    titleModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Hapus Konsultan Survey'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return 'Tempatkan Jadwal Survey Telah Tepat?'
      }
      return ''
    },
    descModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Konsultan yang ditugaskan pada survey ini akan dihapus'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return 'Pastikan jadwal survey yang telah kamu tentukan telah sesuai'
      }
      return ''
    },
    widthDescModalAction () {
      if (this.typeModalAction === 'delete') {
        return '200px'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return '310px'
      }
      return ''
    },
    btnModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Hapus Konsultan Survey'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return 'Simpan Jadwal'
      }
      return ''
    }
  },
  methods: {
    formatDate (date, format) {
      if (!date) return ''
      return moment(date).format(format || 'dddd, DD MMMM YYYY')
    },
    randomBackground () {
      return randomTerseHexColor()
    },
    checkIfReaching (event) {
      // Get mouse coordinates relative to the div
      const card = event.currentTarget
      const cardRect = card.getBoundingClientRect()

      const mouseX = event.clientX - cardRect.left
      const mouseY = event.clientY - cardRect.top

      const radiusWidth = 30
      const radiusHeight = 20
      const spaceFromTop = 0

      const targetLeft = (card.offsetWidth - radiusWidth) / 2
      const targetTop = spaceFromTop
      const targetRight = targetLeft + radiusWidth
      const targetBottom = targetTop + radiusHeight

      // Check if the mouse is within the target area
      this.cursorMove =
        mouseX >= targetLeft &&
        mouseX <= targetRight &&
        mouseY >= targetTop &&
        mouseY <= targetBottom
    },
    resetReaching () {
      this.idHoverCard = null
      this.consultantIdHoverCard = null
    },
    closeModalSelectConsultant () {
      this.modalSelectConsultant = false
    },
    handleSelectConsultant (data, type) {
      if (!data.name) {
        this.modalSelectConsultant = !this.modalSelectConsultant
        this.typeModalConsultant = type || 'bulk'
        this.tempDataConsultant = data
      }
    },
    handleMinimizeSidebar () {
      this.minimize = !this.minimize
    },
    handlePrevNext (type) {
      const tempCurrentDate = JSON.parse(JSON.stringify(moment(this.selectedDate).toDate()))
      const currentDate = moment(this.selectedDate)

      if (type === 'prev') {
        this.selectedDate = currentDate.add(-1, 'days').toDate()
      } else if (type === 'next') {
        this.selectedDate = currentDate.add(1, 'days').toDate()
      }

      const updatedDate = moment(this.selectedDate)
      if (
        moment(tempCurrentDate).year() !== updatedDate.year() ||
        moment(tempCurrentDate).month() !== updatedDate.month()
      ) {
        this.fromPage = {
          year: updatedDate.year(),
          month: updatedDate.month() + 1 // Moment months are zero-based
        }
      }
    },
    handleHoverCard (cardId, consultantId) {
      if (!cardId) {
        this.resetReaching()
      } else {
        this.idHoverCard = cardId
        this.consultantIdHoverCard = consultantId
      }
    },
    handleHoverDragIcon (cardId) {
      this.idHoverDragIcon = cardId
    },
    handleCancelDeleteConsultant () {
      this.modalActionConsultant = false
      this.idHoverCard = this.tempIdHoverCard
      this.consultantIdHoverCard = this.tempConsultantIdHoverCard
    },
    handleDeleteConsultant () {
      this.isLoading = true
      const payload = {
        url: this.url,
        id: this.tempCard.id
      }
      this.$store.dispatch('monitoring/removeSurveySchedule', payload)
        .then(() => {
          this.isLoading = false
          toastMessage('Konsultan Survey Berhasil Dihapus', 'is-dark')

          this.modalActionConsultant = false
          const findIdx = this.cardSurvey.findIndex((item) => item.id === this.tempCard.id)
          this.tempCard.removed = true
          this.tempCard.consultant_id = null

          setTimeout(() => {
            this.cardSurvey.splice(findIdx, 1, this.tempCard)
            this.setDataSurvey(this.cardSurvey, this.initConsultants)
          }, 500)

          setTimeout(() => {
            this.scrollTo(`card-${this.tempCard.id}`)
            this.tempCard = null
          }, 800)
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleDetailConsultant (id) {
      const routeData = this.$router.resolve(`/am/project/survey/${id}/detail`)
      window.open(routeData.href, '_blank')
    },
    resetDraggedCard () {
      this.draggedEvent = null
      this.cursorMove = false
      this.modalActionConsultant = false
      this.idHoverDragIcon = null
    },
    handleConfirmMoveCard () {
      this.isLoading = true
      const payload = {
        url: this.url,
        id: this.tempCard.id,
        consultant_id: this.tempCard.consultant_id,
        start_time: this.tempCard.start_time,
        end_time: this.tempCard.end_time
      }

      if (this.typeModalAction === 'move-sesi-custom') payload.custom = true

      this.$store.dispatch('monitoring/updateSurveySchedule', payload)
        .then(() => {
          if (this.typeModalAction === 'move-sesi-custom') {
            const findIdx = this.sesiCustom.findIndex((item) => item.id === this.tempCard.id)
            if (findIdx > -1) this.sesiCustom.splice(findIdx, 1, { ...this.tempCard, removed: true })
            setTimeout(() => {
              if (findIdx > -1) this.sesiCustom.splice(findIdx, 1)
            }, 500)
          }

          toastMessage('Jadwal Survey Berhasil Diubah', 'is-dark')
          this.resetDraggedCard()
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
          if (this.typeModalAction === 'move') {
            const item = this.cardSurvey.find((item) => item.id === this.tempDraggedEvent.id)
            if (item) {
              item.consultant_id = this.tempDraggedEvent.consultant_id
              item.start_time = this.tempDraggedEvent.start_time
              item.end_time = this.tempDraggedEvent.end_time
            }
            this.resetDraggedCard()
          }
        })
    },
    handleExpandCard () {
      this.isLoading = true
      const payload = {
        url: this.url,
        id: this.tempCard.id,
        consultant_id: this.tempCard.consultant_id,
        start_time: this.tempCard.start_time,
        end_time: this.tempCard.end_time,
        custom: true
      }

      this.$store.dispatch('monitoring/updateSurveySchedule', payload)
        .then(() => {
          toastMessage('Jadwal Survey Berhasil Diubah', 'is-dark')
          this.dataStretching = null
          this.tempCard = null
          this.modalActionConsultant = false
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
          this.handleCancelExpand()
        })
    },
    handleCancelExpand () {
      const findIdx = this.cardSurvey.findIndex((item) => item.id === this.dataStretching.id)
      this.cardSurvey.splice(findIdx, 1, this.dataStretching)
      this.dataStretching = null
      this.tempCard = null
      this.modalActionConsultant = false
    },
    handleCancelMoveCard () {
      const findIdx = this.cardSurvey.findIndex((item) => item.id === this.tempDraggedEvent.id)
      this.resetDraggedCard()
      if (findIdx > -1) {
        if (this.typeModalAction === 'move-sesi-custom') {
          this.cardSurvey.splice(findIdx, 1)
        } else if (this.typeModalAction === 'move') {
          this.cardSurvey.splice(findIdx, 1, this.tempDraggedEvent)
        }
      }
    },
    handleCancelModalAction () {
      if (this.typeModalAction === 'delete') {
        this.handleCancelDeleteConsultant()
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom') {
        this.handleCancelMoveCard()
      } else if (this.typeModalAction === 'expand') {
        this.handleCancelExpand()
      }
    },
    handleConfirmModalAction () {
      if (this.typeModalAction === 'delete') {
        this.handleDeleteConsultant()
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom') {
        this.handleConfirmMoveCard()
      } else if (this.typeModalAction === 'expand') {
        this.handleExpandCard()
      }
    },
    handleSecondaryTooltip (card, consultant) {
      card.removed = false
      this.tempDraggedEvent = card
      if (typeof card.consultant_id === 'string' && card.consultant_id.includes('CONS')) {
        this.handleSelectConsultant(consultant, 'one-consultant')
        this.resetReaching()
      } else {
        this.openDeleteConsultant(card, consultant)
      }
    },
    openDeleteConsultant (card, consultant) {
      this.typeModalAction = 'delete'
      this.tempIdHoverCard = JSON.parse(JSON.stringify(this.idHoverCard))
      this.tempConsultantIdHoverCard = JSON.parse(JSON.stringify(this.consultantIdHoverCard))

      this.resetReaching()
      this.tempCard = {
        ...card,
        consultant
      }
      setTimeout(() => {
        this.modalActionConsultant = !this.modalActionConsultant
      }, 300)
    },
    onDragSesiCustomStart (event, data) {
      this.draggedEvent = JSON.parse(JSON.stringify(data))
      this.typeModalAction = 'move-sesi-custom'
    },
    onDragSesiCustomEnd (event) {
      this.draggedEvent = null
    },
    onDragStart (event, data, consultant) {
      if (data.removed) {
        delete data.removed
      }

      this.typeModalAction = 'move'
      if (!this.cursorMove) return
      this.draggedEvent = { ...data, consultant_id: consultant.id }
      const findIdx = this.cardSurvey.findIndex(
        (item) => item && item.id === data.id
      )

      setTimeout(() => {
        if (findIdx === -1) return
        this.cardSurvey.splice(findIdx, 1)
      }, 0)
    },
    onDragEnd () {
      if (this.draggedEvent) {
        this.cardSurvey.push(this.draggedEvent)
      }
    },
    onDrop (event, timeSlot, consultant) {
      if (this.draggedEvent) {
        if (consultant.empty) {
          this.cardSurvey.push(this.draggedEvent)
          this.draggedEvent = null
          return
        }

        const startIndex = this.timeSurvey.indexOf(timeSlot)
        const duration = this.typeModalAction === 'move-sesi-custom' ? 1
          : this.timeSurvey.indexOf(this.draggedEvent.end_time) -
          this.timeSurvey.indexOf(this.draggedEvent.start_time) // Calculate duration

        const newStartTime = timeSlot
        const newEndTime = this.timeSurvey[startIndex + duration]
        // Check if newEndTime is valid
        if (!newEndTime) {
          toastMessage('Invalid time slot!', 'is-dark')
          return
        }

        this.tempDraggedEvent = JSON.parse(JSON.stringify(this.draggedEvent))

        // Skip overlap check if dropping in original position
        if (
          timeSlot === 'empty' ||
          (this.draggedEvent.start_time === newStartTime &&
            this.draggedEvent.end_time === newEndTime &&
            this.draggedEvent.consultant_id === consultant.id)
        ) {
          this.cardSurvey.push(this.draggedEvent)
          this.draggedEvent = null
          return
        }

        // Overlap detection
        const overlappingEvent = this.cardSurvey.find((e) => {
          return (
            (
              (e.sesi === 'custom' && this.draggedEvent.sesi === 'custom') &&
              (
                e.consultant_id === consultant.id &&
                e.start_time === this.draggedEvent.start_time &&
                e.end_time === this.draggedEvent.end_time
              )
            ) ||
            (
              e.sesi !== 'custom' &&
              e.id !== this.draggedEvent.id &&
              e.consultant_id === consultant.id &&
              (
                (e.start_time >= newStartTime && e.start_time < newEndTime) ||
                (e.end_time > newStartTime && e.end_time <= newEndTime) ||
                (e.start_time <= newStartTime && e.end_time >= newEndTime)
              )
            )
          )
        })

        if (overlappingEvent && (this.typeModalAction !== 'move-sesi-custom' && this.draggedEvent.sesi !== 'custom')) {
          toastMessage('Slot waktu sudah terisi!', 'is-dark')
          return
        }

        // if (consultant.name && consultant.vendor.vendor_name !== this.draggedEvent.vendor_name) {
        //   toastMessage('Vendor Konsultan tidak sesuai!', 'is-dark')
        //   return
        // }

        this.draggedEvent.start_time = newStartTime
        this.draggedEvent.end_time = newEndTime
        this.draggedEvent.consultant_id = consultant.id
        this.draggedEvent.consultant = consultant
        this.draggedEvent.is_additional = false

        this.cardSurvey.push(this.draggedEvent)
        this.tempCard = this.draggedEvent

        if (consultant.id.toString().includes('CONS') && !this.tempDraggedEvent.consultant_id.toString().includes('CONS')) {
          this.resetDraggedCard()
          this.modalActionConsultant = true
        } else if (consultant.id.toString().includes('CONS') && this.draggedEvent.consultant_id.toString().includes('CONS')) {
          this.resetDraggedCard()
        } else {
          this.resetDraggedCard()
          this.modalActionConsultant = true
        }
      }
      // document.removeEventListener('dragover', this.updateCursorPosition)
    },

    onDragEnter (event, time, consultantId) {
      // console.log(event)
    },
    onDragLeave (event) {
      // console.log(event)
    },
    onSelectConsultant (data) {
      const storeCard = []
      const isOneConsultant = this.typeModalConsultant === 'one-consultant'
      let idCard = null
      let isOverlap = false

      if (isOneConsultant) {
        this.cardSurvey.map((item) => {
          if (item.consultant_id === data.id) {
            const overlapping =
              (item.start_time >= this.tempDraggedEvent.start_time && item.start_time < this.tempDraggedEvent.end_time) ||
              (item.end_time > this.tempDraggedEvent.start_time && item.end_time <= this.tempDraggedEvent.end_time) ||
              (item.start_time <= this.tempDraggedEvent.start_time && item.end_time >= this.tempDraggedEvent.end_time)
            if (overlapping) isOverlap = true
          }
        })
      }

      if (isOverlap) {
        toastMessage('Slot waktu sudah terisi!', 'is-dark')
        return
      }

      this.cardSurvey.map((item) => {
        if (item.consultant_id === this.tempDataConsultant.id) {
          storeCard.push(item.id)
          if (!idCard && isOneConsultant) idCard = item.id
        }
      })

      this.isLoading = true
      const payload = {
        url: this.url,
        consultant_id: data.id,
        survey_ids: storeCard
      }
      this.$store
        .dispatch('monitoring/assignMonitoringKonsultan', payload)
        .then(() => {
          this.isLoading = false

          this.cardSurvey.map((item) => {
            if (item.consultant_id === this.tempDataConsultant.id) {
              item.consultant_id = data.id
              item.removed = true
            }
          })

          if (isOneConsultant) {
            setTimeout(() => {
              this.scrollTo(`card-${idCard}`)
            }, 500)
          } else {
            const findIdx = this.consultants.findIndex((item) => item.id === this.tempDataConsultant.id)
            if (findIdx > -1) {
              this.consultants.splice(findIdx, 1, data)
            }
            this.initConsultants.push({
              id: randomConsultantId(),
              is_additional: true
            })
            this.consultants.push({
              id: randomConsultantId(),
              is_additional: true
            })
          }
          toastMessage('Berhasil Assign Konsultan', 'is-dark')
          this.modalSelectConsultant = false
          this.tempDataConsultant = null
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },

    startStretching (event, data) {
      event.preventDefault()

      this.isStretching = true
      this.dataStretching = data
      this.startMouseY = event.clientY

      document.body.style.cursor = 'ns-resize'

      window.addEventListener('mousemove', this.stretchCard)
      window.addEventListener('mouseup', this.stopStretching)
    },
    stretchCard (event) {
      if (this.isStretching) {
        event.preventDefault() // Ensure no unwanted behavior during dragging

        const mouseDiff = event.clientY - this.startMouseY

        const duration = this.timeSurvey.indexOf(this.dataStretching.end_time) -
          this.timeSurvey.indexOf(this.dataStretching.start_time)
        const cardHeight = duration * this.HEIGHT_COLUMN

        // Calculate raw height based on the mouse movement
        const rawHeight = cardHeight + mouseDiff

        // Determine the threshold for snapping (50% of rowHeight)
        const snapThreshold = this.rowHeight / 2

        // Calculate the closest row boundary with snapping
        let newHeight
        if (rawHeight > cardHeight + snapThreshold) {
          // Snap to the next row when moving downward
          newHeight = Math.ceil(rawHeight / this.rowHeight)
        } else if (rawHeight < cardHeight - snapThreshold) {
          // Snap to the previous row when moving upward
          newHeight = Math.floor(rawHeight / this.rowHeight)
        } else {
          // Stay at the current row if within the threshold
          newHeight = Math.round(cardHeight / this.rowHeight)
        }

        // Cap the height to stay within valid row boundaries
        newHeight = Math.max(1, Math.min(newHeight, this.timeSurvey.length))

        this.typeModalAction = 'expand'
        const findIdx = this.timeSurvey.findIndex((item) => item === this.dataStretching.start_time)
        this.tempCard = {
          ...this.dataStretching,
          end_time: this.timeSurvey[findIdx + newHeight]
        }
        const findIdxSurvey = this.cardSurvey.findIndex((item) => item.id === this.dataStretching.id)
        this.cardSurvey.splice(findIdxSurvey, 1, this.tempCard)
      }
    },
    stopStretching () {
      this.isStretching = false
      document.body.style.cursor = ''

      // Remove global event listeners
      window.removeEventListener('mousemove', this.stretchCard)
      window.removeEventListener('mouseup', this.stopStretching)

      if (this.tempCard.end_time !== this.dataStretching.end_time) {
        this.modalActionConsultant = true
      }
    },

    openGuideSesi () {
      this.modalGuide = !this.modalGuide
    },

    getCardsForSlot (timeSlot, consultantId) {
      return this.cardSurvey.filter(
        (item) => item && item.start_time === timeSlot && item.consultant_id === consultantId
      )
    },
    getCardStyle (event, idxTime) {
      const startIndex = this.timeSurvey.indexOf(event.start_time)
      const endIndex = this.timeSurvey.indexOf(event.end_time)
      const rowSpan = endIndex - startIndex // Calculate how many rows to span
      return idxTime === this.timeSurvey.length
        ? 0
        : `${rowSpan * this.HEIGHT_COLUMN - 3}px`
    },
    getMonitoringKonsultan () {
      this.isLoadingTable = true
      this.consultants = []
      this.cardSurvey = []
      this.sesiCustom = []

      const body = {
        url: this.url,
        date: moment(this.selectedDate || new Date()).format('YYYY-MM-DD'),
        search: this.search || null,
        user_id: this.selectedConsultant ? this.selectedConsultant.id : null,
        client_id: this.selectedClient ? this.selectedClient.id : null
      }

      this.$store.dispatch('monitoring/getMonitoringKonsultan', body)
        .then((response) => {
          const res = response.data.data
          this.timeSurvey = ['empty', ...res.timeslots]
          this.setDataSurvey(res.survey_schedules, res.consultants)

          this.sesiCustom = res.sesi_customs || []
          this.isLoadingTable = false
          if (this.isLoading) this.isLoading = false
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingTable = false
          if (this.isLoading) this.isLoading = false
        })
    },
    setDataSurvey (cards, consultants, type) {
      const filterConsultant = consultants.filter(item => item.id.toString().includes('CONS'))
      const filterCard = cards.filter(item => !item.consultant_id)

      const temp = filterConsultant.map(item => {
        return {
          consultant_id: item.id,
          list: cards.filter(c => c.consultant_id === item.id)
        }
      })

      const result = updatedCards(filterCard, temp)

      const resCards = cards.map(obj => {
        const replacement = result.cards.find(item => item.id === obj.id)
        return replacement || { ...obj, removed: false }
      })

      // Convert arr to match listArr's key
      const arrMapped = result.consultants.map(item => ({ ...item, id: item.consultant_id }))

      // Merge while replacing duplicates
      const merged = [...consultants, ...arrMapped].reduce((acc, obj) => {
        acc.set(obj.id, obj) // Replace if ID already exists
        return acc
      }, new Map())

      const resConsultant = Array.from(merged.values())

      if (this.typeModalAction === 'delete') {
        resConsultant.push({
          id: randomConsultantId(),
          is_additional: true
        })
      } else {
        for (let i = 0; i < 5; i++) {
          const additional = {
            id: randomConsultantId(),
            is_additional: true
          }
          resConsultant.push(additional)
        }
      }

      this.cardSurvey = JSON.parse(JSON.stringify(resCards))
      this.initCardSurvey = JSON.parse(JSON.stringify(resCards))

      this.consultants = JSON.parse(JSON.stringify(resConsultant))
      this.initConsultants = JSON.parse(JSON.stringify(resConsultant))
    },
    scrollTo (target) {
      let element = this.$refs[target]
      if (Array.isArray(element)) {
        element = element[0]
      }

      if (element && typeof element.scrollIntoView === 'function') {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      } else {
        console.error('Invalid element:', element)
      }
    },
    getClient () {
      this.isLoadingClient = true
      const payload = {
        url: this.url
      }

      this.$store
        .dispatch('client/getListVendor', payload)
        .then((response) => {
          const res = response.data.data

          this.initDataClients = JSON.parse(JSON.stringify(res.list))
          this.dataClients = JSON.parse(JSON.stringify(res.list))
          this.isLoadingClient = false
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingClient = false
        })
    },
    getListConsultant () {
      this.isLoading = true
      this.$store
        .dispatch('monitoring/getListConsultant', { url: this.url })
        .then((response) => {
          this.listSearchConsultant = response.data.data
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    filteredData () {
      this.isLoadingTable = true
      if (!this.search && !this.selectedClient && !this.selectedConsultant) {
        this.getMonitoringKonsultan()
        return
      }
      let filteredHeader = this.initConsultants
      let filteredSchedule = this.initCardSurvey

      filteredSchedule = filteredSchedule.filter(card =>
        (!this.search || card.survey_id.toString() === this.search) &&
          (!this.selectedClient || card.vendor_id === this.selectedClient.id) &&
          (!this.selectedConsultant || card.consultant_id === this.selectedConsultant.id)
      )

      const consultantIdsInCards = new Set(filteredSchedule.map(card => card.consultant_id))
      filteredHeader = filteredHeader.filter(c => consultantIdsInCards.has(c.id))

      while (filteredHeader.length <= 10) {
        filteredHeader.push({
          empty: true
        })
      }

      setTimeout(() => {
        this.isLoadingTable = false
        this.consultants = filteredHeader
        this.cardSurvey = filteredSchedule
      }, 300)
    }
  },
  mounted () {},
  created () {
    this.isLoading = true
    this.getMonitoringKonsultan()
    this.getClient()
    this.getListConsultant()
  },
  watch: {
    selectedDate () {
      this.getMonitoringKonsultan()
    },
    selectedConsultant () {
      this.filteredData()
    },
    selectedClient () {
      this.filteredData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.sidebar-monitoring {
  width: 20%;
  height: 90vh;
  overflow: auto;
  border-right: 4px solid $color-grey-2;
  position: relative;
  background: $color-grey-6;
  padding-top: 8px;
  transition: width 0.3s ease-in-out;

  &.minimize-sidebar {
    width: 0px;
    overflow: hidden;
  }
}

.body-monitoring {
  width: 80%;
  height: 90vh;
  overflow: auto;
  position: relative;
  transition: all 0.5s ease-in-out;

  &.filled-monitoring {
    width: 100%;
  }
}

.date-minimize-icon {
  width: 0px;
  transition: width 0.5s ease-in-out;
  overflow: hidden;

  &.expand-minimize-icon {
    width: 30px;
    margin-right: 29px;
    margin-left: 18px;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.v-calendar-wrapper-normal {
  width: 100%;
  height: 320px;
  overflow: hidden;
  background: $color-grey-6;
}

.minimize-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  flex-shrink: 0;
  z-index: 2;
  background: white;
}

.header-current-date {
  padding: 17px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eaeaea;
  background: white;
  z-index: 29;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

// ==================== TABLE BODY ====================

.schedule-table {
  display: grid;
  position: relative;
  transition: 0.2s opacity ease-in-out;
  opacity: 1;
  // right: 0;

  &.loading-table {
    // right: 250vw;
    opacity: 0;
  }
}

.header-table {
  display: flex;
  width: 100%;
  position: sticky;
  top: 70px;
  z-index: 30;
  background: #fafafa;
  border-top: 1px solid #e1e1e1;
  transition: top 0.3s ease-in-out;
}

.d-contents {
  display: contents;
}

.cell {
  width: 200px;
  border: 1px solid #e1e1e1;
  text-align: center;
  background: white;

  &.header {
    border: none !important;
    background: #fafafa;
    border-bottom: 1px solid #e1e1e1 !important;
    display: flex;
  }

  &.header-empty {
    background: #fafafa;
    border: none !important;
    z-index: 40;
    border-bottom: 1px solid #e1e1e1 !important;
    width: 100px;
  }

  &.time {
    position: sticky;
    left: 0;
    z-index: 10;
    border: none;
    border-right: 1px solid #e1e1e1;
    width: 100px;
    background: white;
  }

  &.drop-highlight {
    background: salmon;
  }
}

.cell[data-timeslot]:hover {
  background-color: #f0f0f0;
}

.card-events {
  color: white;
  border-radius: 20px;
  margin: 0px 0;
  position: relative;
  min-height: 40px;
  height: 100%;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
  transition: border 0.3s ease-in-out, height 0.3s ease-in-out, width 0.3s ease-in-out, opacity 0.7s ease-in-out;
  border: 1px solid transparent;

  &.remove-card {
    opacity: 0;
  }

  &.card-sesi-custom {
    width: 80%;
    border: 1px solid white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 21;

    .expand-handle {
      cursor: ns-resize;
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 5px;
      background: lightgray;
      opacity: 0;
    }
  }

  &.transparent-card {
    transition: opacity 0.3s ease-in-out !important;
    opacity: 0.5;
  }

  &.opacity-effect {
    opacity: 1;
    animation: opacityEffect 1.3s ease-in-out;
  }
}

@keyframes dropCardEffect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityEffect {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.border-white-1 {
  border: 1px solid white;
}

.body-card-events {
  height: calc(100% - 35px);
  position: relative;
}

.description-card {
}

.tooltip-hover-card {
  width: 0;
  height: 0;
  position: absolute;
  top: 63%;
  left: 130px;
  box-shadow: 0px 10px 40px 0px #2525251f;
  background: white;
  z-index: 50;
  border-radius: 20px;
  transition: 0.2s all ease-in-out;
  opacity: 0;
  overflow: hidden;
}

.tooltip-hover-card.visible {
  height: 250px;
  width: 400px;
  opacity: 1;
}

.tooltip-hover-card {
  .tooltip-header {
    background: #fafafa;
    padding: 15px 20px;

    .tooltip-vendor-img {
      height: 18px;
      margin-right: 10px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .tooltip-date {
    background: #fff4f4;
    border-radius: 20px;
    padding: 12px;
  }
}

.drag-icon-wrapper {
  height: 30px;
}

.drag-icon {
  height: 20px;
  margin-top: 5px;
}

.cursor-move {
  cursor: move;
}

.consultan-img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;

  &img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.vendor-card-img {
  background: white;
  border-radius: 100px;
  height: 22px;
  max-width: 90%;
  padding: 4px 10px;
  overflow: hidden;
  display: inline-flex;

  img {
    height: 100%;
    widows: 100%;
    object-fit: contain;
  }
}

.time-survey {
  position: absolute;
  right: 0;
  top: -11px;

  .line {
    width: 10px;
    height: 2px;
    background: #e1e1e1;
    margin-left: 5px;
  }
}

.previous-next-arrow {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.previous-next-arrow:hover {
  background: #f0f0f0;
}

.modal-delete-consultant {
  padding: 20px;
  padding-bottom: 25px;
  background: white;
  border-radius: 20px;
  width: 35em;
}

.modal-vendor-icon {
  height: 25px;
  border-radius: 100px;
  background: white;
  padding: 4px 10px;

  img {
    height: 100%;
    object-fit: contain;
  }
}

.modal-consultant-img {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background: white;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.floating-card {
  position: absolute;
  top: 0;
  color: white;
  border-radius: 20px;
  height: 70px;
  width: 200px;
  z-index: 1000;
  pointer-events: none;
  box-sizing: border-box;
}

.collapse-sesi-custom-wrapper {
  width: 100%;
  padding: 17px 20px;
  border-top: 1px solid #e1e1e1;
  position: sticky;
  z-index: 30;
  top: 70px;
  left: 0;
  right: 0;
  background: white;
  transition: padding 0.5s ease-in-out, max-height 1s ease-in-out, opacity 0.7s ease-in-out;
  max-height: 450px;
  opacity: 1;

  &.minimize-sidebar {
    padding: 17px 38px;
  }

  &.removed-wrapper-sesi-custom {
    max-height: 0px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    opacity: 0;
  }
}

.collapse-sesi-custom {
  background: linear-gradient(90deg, #891313 0%, #d92727 99.97%);
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
}

.chevron-sesi-custom {
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: rotate(270deg);
}

.chevron-sesi-custom.open {
  transform: rotate(90deg);
  transform-origin: center;
}

.body-sesi-custom {
  background: white;
  padding: 16px 20px;
  padding-bottom: 0;
  min-height: 9em;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-top: 3px;
}

.vendor-img-sesi-custom {
  height: 30px;
  width: 90px;
  border-radius: 100px;
  background: white;
  overflow: hidden;
  padding: 5px 10px;
  margin-right: 10px;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.list-survey-custom-wrapper {
  max-height: 12em;
  width: 100%;
  overflow: auto;
}

.list-sesi-custom {
  display: flex;
  background: #fafafa;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  transition: height 0.5s ease-in-out;
  height: 82px;
  overflow: hidden;

  &.removed-sesi-custom {
    height: 0px;
    padding: 0px;
    margin: 0;
  }
}

.info-vendor-sesi-custom {
  flex: 1;
  border-right: 1px solid #e1e1e1;
  padding-right: 15px;
  margin-right: 20px;
}

.address-sesi-custom {
  flex: 2.5;
  border-right: 1px solid #e1e1e1;
  padding-right: 15px;
  margin-right: 10px;

  div {
    max-width: 35em;
  }
}

.drag-icon-sesi-custom {
  padding: 0 10px;
  height: 35px;
  align-self: center;
  // pointer-events: none;
  cursor: move;
  flex-shrink: 0;
}

.drag-div {
  width: 100px;
  height: 100px;
  background: salmon;
}
</style>

<style>
.v-calendar-wrapper-normal .vc-container {
  border: none !important;
  font-family: 'Averta' !important;
  background: #fafafa;
}

.v-calendar-wrapper-normal .vc-grid-cell {
  margin-bottom: 10px !important;
}

.v-calendar-wrapper-normal .vc-day-content {
  height: 25px !important;
  width: 25px !important;
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-weekday {
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-start {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-end {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-middle {
  height: 100% !important;
  width: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight {
  width: 27px !important;
  height: 27px !important;
}

.search-left-monitoring input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 4px;
}

.collapse-sesi-custom .collapse-trigger {
  width: 100%;
}
</style>

<style>
.search-left-monitoring.client .dropdown-menu .dropdown-content {
  max-height: 150px !important;
}
</style>
