// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import {
  // eslint-disable-next-line no-unused-vars
  responseAPI,
  responseListConsultant,
  guideSessions
} from '../../views/v2/am/monitoring-konsultan/example-data'
import { randomTerseHexColor } from '@/helpers'

/* eslint-disable */
const state = () => ({});

const getters = {};

const actions = {
  getMonitoringKonsultan({ commit, state }, payload) {
    let path = "";
    if (payload.vendor_id) path += `&vendor_id=${payload.vendor_id}`;
    if (payload.user_id) path += `&user_id=${payload.user_id}`;
    if (payload.search) path += `&search=${payload.search}`;

    const date = payload.date
    // const date = "2025-01-14";

    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_MONITORING}?date=${date}${path}`)
      .then((response) => {
        response.data.data.survey_schedules = response.data.data.survey_schedules.map(obj => ({
          ...obj,
          background:
            obj.background ||
            obj.vendor_name.toLowerCase().includes('alfa') ?
            '#276ACA' : obj.vendor_name.toLowerCase().includes('summer') ?
            '#B40007' : randomTerseHexColor()
        }));
        resolve(response)
        }).catch((error) => {
          reject(error)
        })
    });
  },
  getGuideSesi({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: guideSessions });
      }, 700);
      // axios.get(`${payload.url}${process.env.VUE_APP_MONITORING}?date=${payload.date}${path}`)
      //   .then((response) => {
      //     resolve(response)
      //   }).catch((error) => {
      //     reject(error)
      //   })
    });
  },
  getKonsultanSurvey({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: responseListConsultant });
      }, 700);
      // axios.get(`${payload.url}${process.env.VUE_APP_MONITORING}?date=${payload.date}${path}`)
      //   .then((response) => {
      //     resolve(response)
      //   }).catch((error) => {
      //     reject(error)
      //   })
    });
  },
  updateSurveySchedule({ commit, state }, payload) {
    const { id, consultant_id, start_time, end_time } = payload.id;
    const body = {
      id,
      consultant_id,
      start_time,
      end_time
    };

    if (payload.custom) {
      body.custom = payload.custom;
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: { code: 1, message: "success update card" } });
        // reject({
        //   response: {
        //     data: {
        //       code: 500,
        //       message: ['error while updating card']
        //     }
        //   }
        // });
      }, 1000);
      // axios.put(`${payload.url}${process.env.VUE_APP_MONITORING}/update-schedule`, body)
      //   .then((response) => {
      //     resolve(response)
      //   }).catch((error) => {
      //     reject(error)
      //   })
    });
  },
  removeSurveySchedule({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: { code: 1, message: "success remove card" } });
        // reject({
        //   response: {
        //     data: {
        //       code: 500,
        //       message: ['error while removing card']
        //     }
        //   }
        // });
      }, 700);
      // axios.delete(`${payload.url}${process.env.VUE_APP_MONITORING}/remove-schedule`, {id: payload.id})
      //   .then((response) => {
      //     resolve(response)
      //   }).catch((error) => {
      //     reject(error)
      //   })
    });
  },
  assignMonitoringKonsultan({ commit, state }, payload) {
    const body = {
      consultant_id: payload.consultant_id,
      survey_ids: payload.survey_ids
    };
    // survey_schedules: [
    //   {
    //     id: 1 // card id,
    //   },
    //   {
    //     id: 2 // card id
    //   }
    //   ...
    // ]

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          data: { code: 1, message: "success assign consultant" }
        });
        // reject({
        //   response: {
        //     data: {
        //       code: 500,
        //       message: ['error while assigning card']
        //     }
        //   }
        // });
      }, 700);

      // axios.post(`${payload.url}${process.env.VUE_APP_MONITORING}/batch-assign`, body)
      //   .then((response) => {
      //     resolve(response)
      //   }).catch((error) => {
      //     reject(error)
      //   })
    });
  },
  getListConsultant({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${payload.url}${process.env.VUE_APP_DATA_PROJECT_V2}/detail/consultant/list`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
