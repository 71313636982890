<template>
  <SidebarClientTemplate
    :show="showSidebar"
    :disabledSubmit="isEmptyForm"
    @closeSidebar="closeSidebar"
    @submit="handleSubmit($event)"
  >
    <template v-slot:bodySidebar>
      <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
      <div>
        <div v-if="typeContentClient === 'add-edit-client'">
          <ContentAddEditClient
            :close="close"
            :getDataListSurvey="getDataListSurvey"
            :getDataListProject="getDataListProject"
            @changeTypeContent="handleChangeTypeContent($event)"
          />
        </div>
        <div v-else-if="typeContentClient === 'additional-branch'">
          <ContentAdditionalBranch />
        </div>

        <!-- Modal Cofirm Submit Create or Update Client -->
        <ModalConfirm
          :showModal="showModalSubmit"
          imageProps="delete-area-component.png"
          imageClass="mb-3"
          :titleProps="MODAL_TITLE_SUBMIT_FORM"
          :descProps="MODAL_DESC_SUBMIT_FORM"
        >
          <template v-slot:button>
            <div class="is-flex mt-4">
              <ButtonApp @click="handleCloseSubmit" class="mr-3" :isSecondary="true" width="185px" height="52px">
                <p class="averta-bold px-4 py-1">Cek Kembali</p>
              </ButtonApp>
              <ButtonApp @click="handleAddUpdateClient" width="185px" height="52px">
                <p class="averta-bold px-4 py-1">{{ MODAL_BUTTON_SUBMIT_FORM }}</p>
              </ButtonApp>
            </div>
          </template>
        </ModalConfirm>

        <!-- Modal Create Additional Branch -->
        <ModalConfirm
          :showModal="showModalBranch"
          imageProps="sunset.png"
          imageClass="mb-3"
          titleProps="Client Ini Belum Mempunyai Cabang"
          descProps="Silahkan tambah cabang"
        >
          <template v-slot:button>
            <ButtonApp
              height="52px"
              @click="handleAddBranch"
            >
              <p class="averta-bold">Tambahkan Cabang</p>
            </ButtonApp>
          </template>
        </ModalConfirm>

        <!-- Modal Cancel Registration -->
        <ModalConfirm
          :showModal="showModalCancel"
          imageProps="sunset.png"
          imageClass="mb-0"
          :titleProps="MODAL_TITLE_CANCEL_FORM"
          :descProps="MODAL_DESC_CANCEL_FORM"
        >
          <template v-slot:button>
            <div class="is-flex">
              <ButtonApp
                width="185px"
                height="52px"
                @click="handleCloseConfirm"
                class="mr-3"
                :isSecondary="true"
              >
                <p class="averta-bold">Kembali</p>
              </ButtonApp>
              <ButtonApp
                width="185px"
                height="52px"
                @click="resetFormClient"
              >
                <p class="averta-bold">{{ MODAL_BUTTON_CANCEL_FORM }}</p>
              </ButtonApp>
            </div>
          </template>
        </ModalConfirm>
      </div>
    </template>
  </SidebarClientTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { SpinnerIcon } from '@/components/icons'
import { alertErrorMessage, validationPayloadHelper, isObjectsSimilar } from '@/helpers'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalConfirm from './ModalConfirm.vue'
import SidebarClientTemplate from '@/components/sidebar/SidebarClientTemplate.vue'
import ContentAddEditClient from './client-components/ContentAddEditClient.vue'
import ContentAdditionalBranch from './client-components/ContentAdditionalBranch.vue'

export default {
  name: 'SidebarStore',
  props: {
    show: {
      type: Boolean
    },
    close: {
      type: Function
    }
  },
  components: {
    SpinnerIcon,
    ButtonApp,
    ModalConfirm,
    SidebarClientTemplate,
    ContentAddEditClient,
    ContentAdditionalBranch
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getPayloadClient: 'client/getPayloadClient',
      getTypeSidebar: 'client/getClientTypeSidebar',
      getIsEmptyForm: 'client/getClientIsEmptyForm',
      getPayloadDataFormClient: 'client/getPayloadDataFormClient',
      payloadAddBranch: 'client/getPayloadAddBranch'
    })
  },
  data () {
    return {
      isLoadingSubmit: false,
      isEmptyForm: true,
      showSidebar: this.show,
      showModalBranch: false,
      showModalCancel: false,
      showModalSubmit: false,
      typeContentClient: 'add-edit-client', // add-edit-client or additional-branch
      typeSidebar: this.getTypeSidebar,
      vendorID: null,
      initialForm: null,

      MODAL_TITLE_SUBMIT_FORM: '',
      MODAL_DESC_SUBMIT_FORM: '',
      MODAL_BUTTON_SUBMIT_FORM: '',

      MODAL_TITLE_CANCEL_FORM: '',
      MODAL_DESC_CANCEL_FORM: '',
      MODAL_BUTTON_CANCEL_FORM: '',
      getDataListSurvey: [],
      getDataListProject: []
    }
  },
  methods: {
    closeSidebar () {
      const alreadyFilled = Object.values(this.getPayloadDataFormClient).some(value => value)

      let isSimilar = false
      if (this.getTypeSidebar === 'edit') {
        isSimilar = isObjectsSimilar(this.initialForm, this.getPayloadDataFormClient)
      }

      if (isSimilar) {
        this.resetFormClient('additional')
      } else if (alreadyFilled) {
        this.showModalCancel = true
      } else {
        this.resetFormClient('additional')
      }
    },
    conditionalTextModal (value) {
      if (value === 'edit') {
        // On Click Simpan
        this.MODAL_TITLE_SUBMIT_FORM = 'Ubah Client?'
        this.MODAL_DESC_SUBMIT_FORM = 'Pastikan perubahan data telah sesuai'
        this.MODAL_BUTTON_SUBMIT_FORM = 'Simpan Perubahan'

        // On Click Batalkan
        this.MODAL_TITLE_CANCEL_FORM = 'Batalkan Perubahan Client?'
        this.MODAL_DESC_CANCEL_FORM = 'Jika data sudah diisi maka akan dihapus oleh sistem'
        this.MODAL_BUTTON_CANCEL_FORM = 'Batalkan Perubahan'
      } else if (value === 'add') {
        // On Click Simpan
        this.MODAL_TITLE_SUBMIT_FORM = 'Buat Client Baru?'
        this.MODAL_DESC_SUBMIT_FORM = 'Pastikan data client telah terisi dengan benar'
        this.MODAL_BUTTON_SUBMIT_FORM = 'Tambahkan Client'

        // On Click Batalkan
        this.MODAL_TITLE_CANCEL_FORM = 'Batalkan Pendaftaran Client Baru?'
        this.MODAL_DESC_CANCEL_FORM = 'Jika data sudah diisi maka akan dihapus oleh sistem'
        this.MODAL_BUTTON_CANCEL_FORM = 'Batalkan Pendaftaran'
      }
    },
    resetFormClient (type) {
      if (this.typeContentClient === 'additional-branch') {
        this.$store.commit('client/setClearDataPayloadBranch', false)
        setTimeout(() => {
          this.$store.commit('client/setClearDataPayloadBranch', true)
        }, 100)
        this.$store.commit('client/setPayloadAddBranch', {
          type: 'branchName',
          branchName: ''
        })
        this.$store.commit('client/setPayloadAddBranch', {
          type: 'clientSelected',
          clientSelected: {}
        })
      }

      if (type !== 'create') {
        this.showSidebar = false
        this.close()
        this.$store.commit('client/setClearPayloadClient')
        this.typeContentClient = 'add-edit-client'
      }
      this.isLoadingSubmit = false
      this.showModalCancel = false
      this.showModalSubmit = false
      // tambahkan props untuk reset formClient
    },

    createAndEditClient (type) {
      const payload = {
        url: this.url,
        name: this.getPayloadDataFormClient.name,
        company_name: this.getPayloadDataFormClient.company_name,
        project_group_session_ids: this.getPayloadDataFormClient.getSelectDataIdProject,
        survey_group_session_ids: this.getPayloadDataFormClient.getSelectDataIdSurvey,
        primary_group_session_id: this.getPayloadDataFormClient.mainSessionIdProject
      }

      if (type === 'edit') {
        payload.client_id = this.getPayloadDataFormClient.id
      }

      if (this.getPayloadDataFormClient.imgFile) {
        payload.logo = this.getPayloadDataFormClient.imgFile
      }

      this.isLoadingSubmit = true
      this.showModalSubmit = false

      this.$store
        .dispatch(`client/${type === 'edit' ? 'updateClient' : 'createClient'}`, payload)
        .then((response) => {
          const res = response.data.data

          if (type === 'add') {
            this.vendorID = res.id
            this.showModalBranch = true
            const storeToVuex = {
              type: 'clientSelected',
              clientSelected: res
            }
            this.$store.commit('client/setPayloadAddBranch', storeToVuex)
          } else {
            this.$emit('updateEdit')
            this.$buefy.toast.open({
              message: '<p class="averta-regular">Data Berhasil Di Ubah</p>',
              type: 'is-success'
            })
          }

          this.resetFormClient(type === 'edit' ? 'edit' : 'create')
          this.isLoadingSubmit = false
        })
        .catch((error) => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    createAdditionalBranch () {
      const payload = {
        url: this.url,
        data: {
          vendor_id: this.vendorID,
          branch_name: this.payloadAddBranch.branchName,
          users: this.payloadAddBranch.dataPic
        }
      }

      const clientName = this.payloadAddBranch.clientSelected.name

      this.isLoadingSubmit = true
      this.showModalSubmit = false

      this.$store
        .dispatch('client/createBranch', payload)
        .then(() => {
          this.isLoadingSubmit = false
          this.resetFormClient()
          this.$emit('updateEdit')

          this.$buefy.toast.open({
            message: `<p class="averta-regular">Client dan Cabang ${clientName} Berhasil Ditambahkan 🎉</p>`,
            type: 'is-success'
          })
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)
        })
    },
    handleCloseConfirm () {
      this.showModalCancel = false
    },
    handleChangeTypeContent (event) {
      this.typeContentClient = event
    },
    handleSubmit () {
      if (!this.isEmptyForm) {
        this.showModalSubmit = true
      }
    },
    handleCloseSubmit () {
      this.showModalSubmit = false
    },
    handleAddBranch () {
      this.showModalBranch = false
      this.handleChangeTypeContent('additional-branch')
      this.MODAL_TITLE_SUBMIT_FORM = 'Buat Cabang Baru?'
      this.MODAL_DESC_SUBMIT_FORM = 'Pastikan data cabang telah terisi dengan benar'
      this.MODAL_BUTTON_SUBMIT_FORM = 'Tambahkan Cabang'
    },
    handleAddUpdateClient () {
      if (this.getTypeSidebar === 'edit') {
        this.createAndEditClient('edit')
      } else if (this.getTypeSidebar === 'add') {
        if (this.typeContentClient === 'add-edit-client') {
          this.createAndEditClient('add')
        } else if (this.typeContentClient === 'additional-branch') {
          this.createAdditionalBranch()
        }
      }
    },
    getListSession () {
      this.isLoadingSubmit = false
      this.$store
        .dispatch('session/getListSession', { url: this.url })
        .then((response) => {
          this.getDataListSurvey = response.data.data.survey || []
          this.getDataListProject = response.data.data.project || []
        })
        .catch((error) => {
          alertErrorMessage(error)
        })
        .finally(() => {
          this.isLoadingSubmit = false
        })
    }
  },
  created () {
    this.getListSession()
  },
  mounted () {
    this.conditionalTextModal(this.getTypeSidebar)
    this.isEmptyForm = this.getPayloadClient.isEmptyForm
    this.initialForm = JSON.parse(JSON.stringify(this.getPayloadDataFormClient))
  },
  watch: {
    show (value) {
      this.showSidebar = value
      this.initialForm = JSON.parse(JSON.stringify(this.getPayloadDataFormClient))
    },
    getTypeSidebar (value) {
      this.conditionalTextModal(value)
      this.typeSidebar = value
    },
    getIsEmptyForm (value) {
      this.isEmptyForm = value
    },
    payloadAddBranch: {
      handler (value) {
        this.isEmptyForm = validationPayloadHelper(value, 'branch')
      },
      deep: true
    }
  }
}
</script>
