<template>
  <div>
    <div v-for="(itemArea, indexArea) in areas" :key="`${indexArea}-area`">

      <div class="tabs tabs-area-wrapper thin-scroll user-select-text" v-if="selectedAreaId === itemArea.area_id">
        <div class="overflow-x-auto overflow-y-hidden thin-scroll tabs-component-wrapper">
          <ul v-if="sliceComponent1.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent1" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <ul v-if="sliceComponent2.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent2" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <ul v-if="sliceComponent3.length > 0">
            <li v-for="(itemComponent, indexComponent) in sliceComponent3" :key="itemComponent.component_name" :class="{'is-active averta-bold': currentClick === itemComponent.component_id}">
              <a @click="handleClick(itemComponent.component_id)">
                <div class="is-flex is-align-items-center">
                  <span>{{ itemComponent.component_name }}</span>
                  <div
                    v-if="handleValidateForm(indexArea, indexComponent) && showError"
                    class="ml-2"
                  >
                    <AlertIcon />
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>

        <!-- content -->
        <div v-for="(itemComponent, indexComponent) in itemArea.components" :key="`${itemComponent.component_name}-content`">
          <div v-if="currentClick === itemComponent.component_id && selectedAreaId === itemArea.area_id">
            <div
              class="c-w-100"
              v-for="(itemDetail, indexDetail) in itemComponent.detail_components"
              :key="`${indexDetail}-detail`"
            >
              <p
                class="averta-bold is-uppercase white-space-initial"
                :class="{ 'mt-3': indexDetail === 0, 'mt-5': indexDetail > 0 }"
              >
                · {{ itemDetail.detail_component_name }}
              </p>

              <div class="is-flex mt-4 mb-4 note-wrapper p-4">
                <p class="mr-2 white-space-no-wrap">Catatan :</p>
                <p>{{ itemDetail.note }}</p>
              </div>
              <div class="px-4 pb-4 item-job-wrapper">
                <div
                  v-for="(itemItem, indexItem) in itemDetail.item"
                  :key="`${indexItem}-item`"
                  :class="{
                    'border-bottom': indexItem + 1 !== itemDetail.item.length,
                  }"
                >
                  <div
                    v-if="(isProjectUnitPrice && !itemItem.note_item_id && !itemItem.note_item_working && !itemDetail.detail_component_name.toLowerCase().includes('lain-lain'))"
                    class="py-4"
                  >
                    <p class="averta-bold mb-4">
                      Item Pekerjaan
                      <span style="color: red">*</span>
                    </p>
                    <div class="flex-center-vertical is-justify-content-space-between">
                     <div class="is-flex is-flex-direction-row gap-2">
                      <ButtonApp :is-bold="true" height="40px" @click="handleOpenModalNote({indexArea, indexComponent, indexDetail, indexItem})">
                        <div class="is-flex is-align-items-center is-justify-content-center">
                          <p class="py-2 mr-2">Pilih Item Pekerjaan</p>
                        </div>
                      </ButtonApp>
                      <ButtonApp :is-bold="true" height="40px" :isSecondary="true" @click="handleOpenSidebarKuotaSesiPengerjaan">
                        <div class="is-flex is-align-items-center is-justify-content-center">
                          <p class="py-2 mr-2">+ Tambah Item Pekerjaan</p>
                        </div>
                      </ButtonApp>
                     </div>

                      <div
                        v-if="indexItem > 0"
                        class="trash-icon ml-2 is-clickable"
                        @click="
                          handleDeleteItem(indexComponent, indexDetail, indexItem)
                        "
                      >
                        <img :src="require('@/assets/img/icon-delete.png')" alt="">
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="py-4 border-bottom">
                      <p class="averta-bold mb-3">
                        Item Pekerjaan
                        <span style="color: red">*</span>
                      </p>
                      <div class="is-flex">
                        <div class="flex-1">
                          <div class="style-textarea-wrapper">
                            <div v-if="(isProjectUnitPrice && itemItem.note_item_id) && !itemDetail.detail_component_name.toLowerCase().includes('lain-lain')" class="flex-center is-justify-content-space-between color-grey px-3 pt-2">
                              <p class="color-grey">ID: {{itemItem.note_item_id || ''}}</p>
                              <div class="is-clickable" @click="handleOpenModalNote({indexArea, indexComponent, indexDetail, indexItem, id: itemItem.id})">
                                <EditIcon color="#E10009" />
                              </div>
                            </div>
                            <textarea
                              :rows="(isProjectUnitPrice && itemItem.note_item_id) && !itemDetail.detail_component_name.toLowerCase().includes('lain-lain') || isSM ? '3' : '2'"
                              class="mt-1 textarea style-textarea bg-white"
                              v-model="itemItem.note_item_working"
                              @input="(e) => handleChangeValidate(e.target.value, itemDetail, itemItem)"
                              placeholder="Isi disini"
                              :disabled="(isProjectUnitPrice && itemItem.note_item_id) && !itemDetail.detail_component_name.toLowerCase().includes('lain-lain')"
                            ></textarea>
                          </div>
                          <ErrorComponent v-if="!itemItem.note_item_working && showError" />
                          <ErrorComponent v-if="isEmptyNoteItemWorking(itemItem.note_item_working, itemDetail.item, indexArea, indexComponent, indexDetail, indexItem)" title="Tidak boleh sama" />
                        </div>
                        <div
                          v-if="indexItem > 0"
                          class="trash-icon ml-2 is-clickable"
                          @click="
                            handleDeleteItem(indexComponent, indexDetail, indexItem)
                          "
                        >
                          <img :src="require('@/assets/img/icon-delete.png')" alt="">
                        </div>
                      </div>
                    </div>
                    <div class="py-4 bg-softgray">
                      <p class="averta-bold mb-4">Detail Pekerjaan</p>
                      <div class="columns">
                        <div class="column is-6">
                          <p>
                            Volume Area Pengerjaan
                            <span style="color: red">*</span>
                          </p>
                          <input
                            min="0"
                            type="number"
                            class="input mt-2 border-input"
                            placeholder="Masukan Volume Area Pengerjaan"
                            v-model.number="itemItem.volume"
                            @input="handleChangeValidate"
                          />
                          <ErrorComponent v-if="!itemItem.volume && showError" />
                        </div>
                        <div class="column is-6">
                          <p>
                            Satuan
                            <span style="color: red">*</span>
                          </p>
                          <section class="w-100 mt-2 border-input">
                            <b-field>
                              <b-autocomplete
                                v-model="itemItem.unit_name"
                                placeholder="Pilih Satuan"
                                :data="filteredDataSatuan"
                                field="name"
                                @select="
                                  (option) =>
                                    handleSelectUnit(
                                      option,
                                      indexComponent,
                                      indexDetail,
                                      indexItem
                                    )
                                "
                                @input="
                                  (e) =>
                                    handleChangeInput(
                                      e,
                                      indexArea,
                                      indexComponent,
                                      indexDetail,
                                      indexItem
                                    )
                                "
                                open-on-focus
                                clearable
                                @focus="resetSearchSatuan"
                                :disabled="isProjectUnitPrice && itemItem.note_item_id"
                              ></b-autocomplete>
                            </b-field>
                          </section>
                          <ErrorComponent v-if="((!itemItem.unit_id || !itemItem.unit_name) && showError) || isValidInputSatuan(itemItem.unit_name)" :title="isValidInputSatuan(itemItem.unit_name)" />
                        </div>
                      </div>
                    </div>
                    <div class="py-4 pb-5">
                      <p class="averta-bold mb-4">Daftar Keahlian Tukang</p>
                      <div
                        v-for="(itemWorkers, indexWorkers) in itemItem.workers"
                        :key="`${indexWorkers}-workers`"
                        class="is-flex is-justify-content-space-between mb-3 w-100 flex-wrap-sm-wrap"
                      >
                        <div class="w-sm-100 is-flex">
                          <div :class="{ 'w-sm-100': indexWorkers === 0 }">
                            <p>
                              Keahlian Tukang
                              <span style="color: red">*</span>
                            </p>
                            <div
                              v-if="itemWorkers.skill_name"
                              class="input-text p-2 mt-2 is-clickable color-red is-flex is-align-items-center"
                              @click="
                                handleShowModal(
                                  indexComponent,
                                  indexDetail,
                                  indexItem,
                                  indexWorkers
                                )
                              "
                            >
                              <div
                                class="input-img mr-2 is-flex is-align-items-center"
                              >
                                <img
                                  :src="itemWorkers.skill_image"
                                  style="height: 100%"
                                />
                              </div>
                              <p>{{ itemWorkers.skill_name }}</p>
                            </div>
                            <p
                              v-else
                              class="input-text p-2 mt-2 is-clickable color-red"
                              @click="
                                handleShowModal(
                                  indexComponent,
                                  indexDetail,
                                  indexItem,
                                  indexWorkers
                                )
                              "
                            >
                              Silahkan pilih keahlian
                            </p>
                            <ErrorComponent v-if="!itemWorkers.skill_name && showError" />
                          </div>
                          <div
                            v-if="indexWorkers > 0"
                            class="flex-1 trash-icon ml-2 is-clickable d-md-none"
                            style="top: 2em"
                            @click="
                              handleDeleteMaterial(
                                indexComponent,
                                indexDetail,
                                indexItem,
                                indexWorkers
                              )
                            "
                          >
                            <img :src="require('@/assets/img/icon-delete.png')" alt="">
                          </div>
                        </div>
                        <div>
                          <div class="is-flex">
                            <div class="mr-4 flex-1 keahlian-wrapper">
                              <p>
                                Maksimum Hari Kerja
                                <span style="color: red">*</span>
                              </p>
                              <input
                                :disabled="!duration || duration < 1"
                                type="number"
                                :max="duration"
                                min="0"
                                oninput="validity.valid||(value='');"
                                v-model.number="itemWorkers.max_day"
                                class="input-number mt-2 averta-bold py-3"
                                @input="handleChangeValidateSkill(areas)"
                              />
                              <ErrorComponent v-if="(!itemWorkers.max_day || itemWorkers.max_day > duration) && showError" :title="!itemWorkers.max_day ? 'Belum Diisi' : itemWorkers.max_day > duration && 'Melebihi Durasi Proyek'" />
                            </div>
                            <div class="flex-1 keahlian-wrapper">
                              <p>
                                Jumlah Tukang
                                <span style="color: red">*</span>
                              </p>
                              <input
                                type="number"
                                min="0"
                                oninput="validity.valid||(value='');"
                                v-model.number="itemWorkers.total_worker"
                                class="input-number mt-2 averta-bold py-3"
                                :class="{'input-with-btn': indexWorkers > 0}"
                                @input="handleChangeValidateSkill(areas)"
                              />
                              <ErrorComponent v-if="!itemWorkers.total_worker && showError" />
                            </div>
                            <div
                              v-if="indexWorkers > 0"
                              class="flex-1 trash-icon ml-2 is-clickable is-hidden-mobile"
                              style="top: 2em"
                              @click="
                                handleDeleteMaterial(
                                  indexComponent,
                                  indexDetail,
                                  indexItem,
                                  indexWorkers
                                )
                              "
                            >
                              <img :src="require('@/assets/img/icon-delete.png')" alt="">
                            </div>
                          </div>
                          <div
                            class="not-fill mt-2"
                            :class="{ 'w-92': indexWorkers > 0 }"
                            v-if="!duration || duration < 1"
                          >
                            <AlertIcon />
                            <p class="averta-bold color-red fs-12 ml-1">
                              Tentukan Durasi Proyek Terlebih Dahulu
                            </p>
                          </div>
                        </div>
                      </div>
                      <p
                        @click="handleAddSkills(indexComponent, indexDetail, indexItem)"
                        class="mt-3 text-primary-gradient averta-bold is-clickable is-inline-block"
                      >
                        + Tambah Keahlian Lain
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p
                @click="handleAddItemWorking(indexComponent, indexDetail)"
                class="mt-2 text-primary-gradient averta-bold has-text-centered is-clickable mt-3"
              >
                + Tambah Item Pekerjaan Lain
              </p>
            </div>
          </div>
        </div>
      </div>

      <b-modal width="716px" v-model="isModalActive" :can-cancel="false">
        <div class="modal-template">
          <div class="modal-body w-100">
            <header
              class="is-flex is-align-items-center is-justify-content-space-between"
            >
              <p class="averta-bold is-size-5">Pilih Tukang</p>
              <div class="is-clickable" @click="isModalActive = false">
                <b-icon icon="close"></b-icon>
              </div>
            </header>
            <div class="wrap-content mt-5">
              <div class="mx-5 mb-5">
                <label class="is-size-6">Pilih Keahlian Tukang</label>
                <input
                  class="input mt-2 mb-4"
                  v-model="searchSkill"
                  @input="debouncedSearchSkill"
                  type="text"
                  icon="magnify"
                  placeholder="Cari Keahlian"
                />

                <div v-if="skills.length > 0">
                  <div
                    v-for="item, indexSkill in skills"
                    :key="`${indexSkill}-skill`"
                    class="is-flex is-justify-content-space-between is-align-items-center mb-4"
                  >
                    <div class="is-flex is-align-items-center">
                      <div class="img-wrapper p-2 mr-3">
                        <img :src="item.image_uri" alt style="height: 100%" />
                      </div>
                      <p>{{ item.name }}</p>
                    </div>
                    <div
                      class="is-clickable is-flex"
                      @click="handleSelectSkills(item)"
                    >
                      <PlusGradient />
                    </div>
                  </div>
                </div>
                <p v-else class="is-italic">No Data Found</p>
              </div>
              <!-- <footer
                class="py-5 is-flex is-justify-content-flex-end footer-shadow"
              >
                <b-button
                  class="btn-disabled averta-bold px-5 is-flex is-justify-content-center mr-5"
                >
                  <CheckIcon class="mr-3 check-position" />
                  <span class="text-btn">Tambahkan</span>
                </b-button>
              </footer> -->
            </div>
          </div>
        </div>
      </b-modal>

      <SidebarKuotaSesiPengerjaan
      :isHandleOpenSidebarKuotaSesiPengerjaan="isHandleOpenSidebarKuotaSesiPengerjaan"
      @closeSidebar="closeSidebar" />

      <ModalItemWorking
        :showModal="showModalNote"
        :editId="selectedNoteId"
        :disabledId="disabledNoteId"
        @submit="$event => handleSubmitItemWorking($event)"
        @closeModal="closeModalNote"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from '@/helpers'
import { mapGetters } from 'vuex'
import {
  PlusGradient,
  CheckIcon,
  AlertIcon,
  EditIcon
} from '@/components/icons'
import ErrorComponent from '@/components/ErrorComponent.vue'
// import ButtonRedSecondary from '@/components/button/ButtonRedSecondary.vue'
import ModalItemWorking from '@/components/modal/ModalItemWorking'
import ButtonApp from '@/components/button/ButtonApp.vue'
import SidebarKuotaSesiPengerjaan from '@/components/sidebar/SidebarKuotaSesiPengerjaan.vue'

export default {
  name: 'TabComponentOne',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CheckIcon,
    PlusGradient,
    AlertIcon,
    EditIcon,
    ErrorComponent,
    // ButtonRedSecondary,
    ModalItemWorking,
    ButtonApp,
    SidebarKuotaSesiPengerjaan
  },
  props: [
    'duration',
    'dataAreas',
    'dataComponents',
    'selectedAreaId',
    'selectedComponentId',
    'indexArea',
    'showError',
    'isUseUnitPrice'
  ],
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      dataUpdateItemPekerjaan: 'project/getDataUpdateItemPekerjaanBS',
      getDataPilihTukang: 'project/getDataPilihTukang'
    }),
    isSM () {
      return this.user.roles[0].name === 'Site Manager'
    },
    isBS () {
      if (this.user.roles[0].name === 'Account Manager' || this.user.roles[0].name === 'Business Support') {
        return true
      } return false
    },
    filteredDataSatuan () {
      return this.satuan.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.searchSatuan.toLowerCase()) >= 0
        )
      })
    },
    isEmptyNoteItemWorking () {
      return (note, arrItem, indexArea, indexComponent, indexDetail, indexItem) => {
        const newObj = {
          area: indexArea,
          component: indexComponent,
          detail: indexDetail,
          item: indexItem
        }

        const found = this.validateNoteItemWorking.some(
          (o) =>
            o.area === indexArea &&
            o.component === indexComponent &&
            o.detail === indexDetail &&
            o.item === indexItem
        )
        const idx = this.validateNoteItemWorking.findIndex(
          (x) =>
            x.area === indexArea &&
            x.component === indexComponent &&
            x.detail === indexDetail &&
            x.item === indexItem
        )

        let isDuplicate = false
        let noteDuplicate = ''
        const values = []

        for (const item of arrItem) {
          if (item.note_item_working) {
            const value = item.note_item_working.toLowerCase().replace(/\s+/g, '') // lowercase & remove spacing

            if (values.includes(value)) { // Found a duplicate value
              isDuplicate = true
              noteDuplicate = item.note_item_working.toLowerCase().replace(/\s+/g, '')
              break
            }
            values.push(value)
          }
        }

        if (arrItem.length > 1 && isDuplicate && noteDuplicate === note.toLowerCase().replace(/\s+/g, '')) {
          if (found) {
            this.validateNoteItemWorking[idx] = newObj
          } else {
            this.validateNoteItemWorking.push(newObj)
          }
        } else {
          if (found) {
            this.validateNoteItemWorking.splice(idx, 1)
          }
        }

        return arrItem.length > 1 && isDuplicate && noteDuplicate === note.toLowerCase().replace(/\s+/g, '')
      }
    },
    isProjectUnitPrice () {
      return this.getDataPilihTukang && this.getDataPilihTukang.is_use_unit_price
    }
  },
  data () {
    return {
      areas: this.dataAreas,
      isModalActive: false,
      skills: [],
      satuan: [],
      indexSelected: null,
      openOnFocus: false,
      searchSatuan: '',
      searchSkill: '',
      selectedSatuan: null,
      validateForm: [],
      validateNoteItemWorking: [],
      isError: false,
      isChange: false,
      currentClick: this.selectedComponentId,
      sliceComponent1: this.dataComponents.sliceComponent1 || [],
      sliceComponent2: this.dataComponents.sliceComponent2 || [],
      sliceComponent3: this.dataComponents.sliceComponent3 || [],

      showModalNote: false,
      tempIndexModalNote: null,
      selectedNoteId: null,
      disabledNoteId: [],
      isHandleOpenSidebarKuotaSesiPengerjaan: false
    }
  },
  methods: {
    isEmptyItem (obj) {
      for (var key in obj) {
        // else if (key === 'note_item_id') {
        //   if (this.isUseUnitPrice && (obj[key] === null || obj[key] === '')) {
        //     return true
        //   }
        // }
        if (key !== 'workers' && key !== 'note_item_id' && key !== 'max_volume_per_session' && key !== 'session_quota') {
          if (obj[key] === null || obj[key] === '') {
            return true
          }
        } else if (key === 'workers') {
          for (var itemWorker of obj.workers) {
            for (const keyWorker in itemWorker) {
              if (keyWorker !== 'skill_image') {
                if (
                  itemWorker[keyWorker] === '' ||
                  itemWorker[keyWorker] === null ||
                  itemWorker[keyWorker] < 1 ||
                  itemWorker.max_day > this.duration
                ) {
                  return true
                }
              }
            }
          }
        }
      }

      return false
    },
    isEmptyWorker (obj) {
      if (!obj || obj.length < 1) return true
      for (var key in obj) {
        if (obj.max_day > 0 || obj.total_worker > 0) {
          return false
        } else if (
          obj[key] === null ||
          obj[key] === undefined ||
          obj[key] === '' ||
          obj[key].length < 1
        ) {
          return true
        }
      }
      return false
    },
    isValidInputSatuan (keyword) {
      if (!keyword) {
        return 'Belum Diisi'
      }

      const findItem = this.satuan.find((val) => val.name === keyword)
      if (!findItem) {
        return 'Tidak valid'
      }
    },
    getDataSkillTukang (keyword) {
      const payload = {
        url: this.url,
        search: keyword || ''
      }
      this.$store
        .dispatch('skill/getListSkills', payload)
        .then((response) => {
          this.skills = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data Skill Tukang, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    getDataUnitSatuan () {
      const payload = {
        url: this.url,
        type: 'volume'
      }
      this.$store
        .dispatch('project/getListUnit', payload)
        .then((response) => {
          this.satuan = response.data.data
        })
        .catch((error) => {
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data Unit Satuan, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    handleValidateForm (indexArea, indexComp) {
      if (this.validateForm.find(item => item.area === indexArea && item.component === indexComp) || this.validateNoteItemWorking.find(item => item.area === indexArea && item.component === indexComp)) {
        return true
      } else {
        return false
      }
    },
    handleChangeEmit () {
      this.$emit('handleChangeStep', true)
    },
    handleChangeValidate () {
      this.handleChangeEmit()
    },
    handleChangeValidateSkill (arr) {
      this.validateAreas(arr)
      this.handleChangeEmit()
    },
    handleDeleteMaterial (indexComponent, indexDetail, indexItem, indexWorkers) {
      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item[indexItem].workers.splice(indexWorkers, 1)
      this.handleChangeEmit()
    },
    handleDeleteItem (indexComponent, indexDetail, indexItem) {
      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image delete area component',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Hapus Item Pekerjaan?</p>',
        html: '<p class="averta-reguler">Seluruh informasi item pekerjaan ini akan terhapus.</p>',
        showCancelButton: true,
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.areas[this.indexArea].components[indexComponent].detail_components[
            indexDetail
          ].item.splice(indexItem, 1)

          // const idx = this.validateForm.findIndex(
          //   (x) =>
          //     x.area === this.indexArea &&
          //     x.component === indexComponent &&
          //     x.detail === indexDetail &&
          //     x.item === indexItem
          // )
          // this.validateForm.splice(idx, 1)

          this.validateForm = []
          this.validateNoteItemWorking = []

          this.handleChangeEmit()
          this.validateAreas(this.areas)
        }
      })
    },
    handleSelectSkills (props) {
      const { indexComponent, indexDetail, indexItem, indexWorkers } =
        this.indexSelected
      const obj =
        this.areas[this.indexArea].components[indexComponent].detail_components[
          indexDetail
        ].item[indexItem].workers[indexWorkers]

      if (this.isEmptyWorker(obj)) {
        this.areas[this.indexArea].components[indexComponent].detail_components[
          indexDetail
        ].item[indexItem].workers[indexWorkers] = {
          max_day: obj.max_day || 0,
          total_worker: obj.total_worker || 0,
          skill_id: null,
          skill_image: null,
          skill_name: null
        }
      }

      if (!('max_day' in obj)) {
        this.areas[this.indexArea].components[indexComponent].detail_components[
          indexDetail
        ].item[indexItem].workers[indexWorkers].max_day = 0
      }

      if (!('total_worker' in obj)) {
        this.areas[this.indexArea].components[indexComponent].detail_components[
          indexDetail
        ].item[indexItem].workers[indexWorkers].total_worker = 0
      }

      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item[indexItem].workers[indexWorkers].skill_id = props.id
      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item[indexItem].workers[indexWorkers].skill_image = props.image_uri
      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item[indexItem].workers[indexWorkers].skill_name = props.name

      this.validateAreas(this.areas)

      this.isModalActive = false
      this.indexSelected = null
    },
    resetSearchSatuan () {
      this.searchSatuan = ''
    },
    handleSelectUnit (option, indexComponent, indexDetail, indexItem) {
      if (option) {
        this.areas[this.indexArea].components[indexComponent].detail_components[
          indexDetail
        ].item[indexItem].unit_name = option.name
        this.areas[this.indexArea].components[indexComponent].detail_components[
          indexDetail
        ].item[indexItem].unit_id = option.id
        this.$emit('handleChangeStep', true)
      }
    },
    handleChangeInput (e, indexArea, indexComponent, indexDetail, indexItem) {
      this.searchSatuan = e
      const findItem = this.satuan.find((val) => val.name === e)
      if (!findItem) {
        this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item[indexItem].unit_id = null
      }
    },
    handleAddSkills (indexComponent, indexDetail, indexItem) {
      const newWorkers = {
        max_day: 0,
        total_worker: 0,
        skill_id: null,
        skill_image: null,
        skill_name: null
      }
      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item[indexItem].workers.push(newWorkers)
    },
    handleAddItemWorking (indexComponent, indexDetail) {
      const itemWorking = {
        note_item_working: null,
        volume: null,
        unit_id: null,
        unit_name: null,
        workers: [{
          max_day: 0,
          total_worker: 0,
          skill_id: null,
          skill_image: null,
          skill_name: null
        }]
      }
      if (this.dataUpdateItemPekerjaan.isUpdate) {
        itemWorking.is_from_bs = true // on progress
      }
      this.areas[this.indexArea].components[indexComponent].detail_components[
        indexDetail
      ].item.push(itemWorking)
      this.$emit('handleChangeStep', true)
    },
    handleShowModal (indexComponent, indexDetail, indexItem, indexWorkers) {
      this.searchSkill = ''
      this.indexSelected = {
        indexComponent,
        indexDetail,
        indexItem,
        indexWorkers
      }
      this.isModalActive = true
    },
    handleIsChange () {
      this.$emit('handleIsChange', true)
    },
    handleClick (value) {
      this.currentClick = value
    },
    handleOpenModalNote (props) {
      this.tempIndexModalNote = props
      this.showModalNote = !this.showModalNote
      this.selectedNoteId = props.id || null
    },
    closeModalNote () {
      this.showModalNote = false
      this.tempIndexModalNote = null
    },
    handleSubmitItemWorking (value) {
      const body = {
        id: this.selectedNoteId || '-',
        note_item_working: value.note_item_working || value.note,
        note_item_id: value.id,
        volume: null,
        unit_id: value.unit_id,
        unit_name: value.unit_name || value.name,
        workers: [{
          max_day: 0,
          total_worker: 0,
          skill_id: null,
          skill_image: null,
          skill_name: null
        }]
      }

      const { indexArea, indexComponent, indexDetail, indexItem } = this.tempIndexModalNote
      this.areas[indexArea].components[indexComponent].detail_components[indexDetail].item.splice(indexItem, 1, body)
      this.closeModalNote()

      if (value.id && !this.disabledNoteId.includes(value.id)) {
        this.disabledNoteId.push(value.id)
      }
      this.validateAreas(this.areas)
    },
    async validateAreas (arr) {
      if (!arr || arr.length < 1) {
        return
      }
      await arr.map((itemArea, indexArea) => {
        itemArea.components.map((itemComp, indexComp) => {
          itemComp.detail_components.map((itemDetail, indexDetail) => {
            itemDetail.item.map((itemItem, indexItem) => {
              const newObj = {
                area: indexArea,
                component: indexComp,
                detail: indexDetail,
                item: indexItem
              }
              const found = this.validateForm.some(
                (o) =>
                  o.area === indexArea &&
                  o.component === indexComp &&
                  o.detail === indexDetail &&
                  o.item === indexItem
              )
              const idx = this.validateForm.findIndex(
                (x) =>
                  x.area === indexArea &&
                  x.component === indexComp &&
                  x.detail === indexDetail &&
                  x.item === indexItem
              )
              const isEmptyItem = this.isEmptyItem(itemItem)
              if (isEmptyItem) {
                if (found) {
                  this.validateForm[idx] = newObj
                } else {
                  this.validateForm.push(newObj)
                }
              } else {
                if (found) {
                  this.validateForm.splice(idx, 1)
                }
              }
            })
          })
        })
      })
      const objValidation = {
        validateForm: this.validateForm,
        validateNoteItem: this.validateNoteItemWorking
      }
      this.$emit('handleValidateForm', objValidation)
      this.$emit('handleChangeStep', true)
    },
    handleOpenSidebarKuotaSesiPengerjaan () {
      this.isHandleOpenSidebarKuotaSesiPengerjaan = true
    },
    closeSidebar () {
      this.isHandleOpenSidebarKuotaSesiPengerjaan = false
    },
    debouncedSearchSkill: debounce(function () {
      this.getDataSkillTukang(this.searchSkill)
    }, 500)

  },
  created () {
    this.getDataSkillTukang()
    this.getDataUnitSatuan()
    this.validateAreas(this.areas)
  },
  mounted () {
    this.currentClick = this.selectedComponentId
  },
  watch: {
    areas: {
      handler: function (newVal) {
        if (newVal || newVal > 0) {
          if (this.user.roles[0].name === 'Site Manager' || this.user.roles[0].name === 'Konsultan Survey' || this.user.roles[0].name === 'Account Manager') {
            this.handleIsChange()
          }
          this.validateAreas(newVal)
        }
      },
      deep: true
    },
    dataAreas (newVal) {
      this.areas = newVal
    },
    dataComponents (newVal) {
      this.sliceComponent1 = newVal.sliceComponent1 || []
      this.sliceComponent2 = newVal.sliceComponent2 || []
      this.sliceComponent3 = newVal.sliceComponent3 || []
    },
    indexArea () {
      // use IndexArea because cant detect selectedComponentId with selectedComponentId watch
      this.currentClick = this.selectedComponentId
    },
    showError (newVal) {
      this.isError = newVal
    },
    duration (newVal) {
      this.validateAreas(this.areas)
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.w-100 {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.input-wrapper {
  width: 60%;
}

.color-red {
  color: #d9272d !important;
}

.note-wrapper {
  background: $color-grey-6;
  border-radius: 100px;
}

.item-job-wrapper {
  background: $color-grey-6;
  border-radius: 12px;
}

.border-input {
  border: 1px solid $color-grey-2;
  border-radius: 10px;
}

.trash-icon {
  position: relative;
  bottom: 5px;
}

.trash-icon svg {
  width: 25px;
  height: 25px;
}

.input-text {
  width: auto;
  min-width: 15em;
  border: 1px solid $color-grey-2;
  border-radius: 10px;
  background-color: #fff;
}

.input-number {
  text-align: center;
  width: 13em;
  border: 1px solid $color-grey-2;
  border-radius: 10px;
}

.input-number:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-with-btn {
  width: calc(10em + 10px) !important;
}

.modal-template {
  margin: auto;
  max-height: 40em;
}

.modal-body {
  overflow: hidden;
  background: white;
  border-radius: 10px;
  height: 100%;
}

.wrap-content {
  height: 500px;
  overflow-y: auto;
}

.modal-template header {
  background-color: #911a1e;
  height: 5em;
  color: white;
  padding: 0 20px;
}

.img-wrapper {
  width: 59px;
  height: 59px;
  box-shadow: 6px 7px 50px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow: hidden;
}

.btn-active {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  color: white;
}

.btn-disabled {
  background-color: #9a9a9a;
  border-radius: 100px;
  color: white;
}

.footer-shadow {
  box-shadow: 0px -3px 30px 0px #00000012;
}

.text-primary-gradient {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.check-position {
  position: relative;
  top: 3px;
}

.text-btn {
  position: relative;
  bottom: 3px;
}

.input-img {
  height: 25px;
  min-width: 25px;
}

.not-fill {
  background: rgba(255, 0, 0, 0.1);
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
}

.border-bottom {
  border-bottom: 1px dashed $color-grey-2;
}

.fs-12 {
  font-size: 12px;
}

.w-92 {
  width: 92% !important;
}

@media only screen and (min-width: 600px) {
  .d-md-none {
    display: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .w-sm-100 {
    width: 100%;
  }

  .input-text {
    width: 100%;
  }

  .input-number {
    width: 100%;
  }

  .flex-wrap-sm-wrap {
    flex-wrap: wrap;
  }

  .keahlian-wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;
  }
}
</style>
