import Dashboard from '../views/v2/sm/Dashboard.vue'
import ListProject from '../views/v2/sm/project/ListProject.vue'
import NewOffering from '../views/v2/project/NewOffering.vue'
import DetailProyek from '../views/v2/project/detail-proyek/DetailProyek.vue'
import Notification from '../views/v2/notification/Notification.vue'
import DetailSurvey from '../views/v2/project/detail-survey/views/DetailSurvey'
import SearchGlobal from '../views/v2/search/SearchGlobal.vue'
import ViewsRating from '../views/v2/sm/views-rating/index.vue'
import DetailsViewsRating from '../views/v2/sm/details-views-rating/index.vue'
import SurveyComplain from '../views/v2/am/project/survey-complaint/SurveyComplain.vue'
import SpecificSearch from '../views/v2/search/SpecificSearch.vue'
import MonitoringKonsultan from '../views/v2/am/monitoring-konsultan/views/MonitoringKonsultan.vue'

const routesSM = [
  {
    path: '/sm/dashboard',
    name: 'SM Dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      const rs = localStorage.getItem('webapp_shell_kanggo')
      const auth = JSON.parse(rs)
      if (auth.user.is_default_password === 1) {
        next({ name: 'ChangeDefaultPassword' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Account Manager') {
        next({ name: 'AM Dashboard' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name.split(' ') === 'Konsultan') {
        next({ name: 'Home' })
      } else if (auth.user.is_default_password === 0 && auth.user.roles[0].name === 'Purchasing') {
        next({ name: 'Purchasing Dashboard' })
      } else if (!auth) {
        next({ name: 'Login' })
      } else {
        next()
      }
    }
  },
  {
    path: '/sm/project',
    name: 'Project SM',
    component: ListProject
  },
  {
    path: '/sm/project/survey/:surveyId/detail',
    name: 'Detail Project SM',
    component: DetailSurvey
  },
  {
    path: '/sm/project/:surveyId/update-offering',
    name: 'New Offering SM',
    component: NewOffering
  },
  {
    path: '/sm/project/:proyekId/detail',
    name: 'Detail Proyek SM',
    component: DetailProyek
  },
  {
    path: '/sm/notification',
    name: 'NotificationSM',
    component: Notification
  },
  {
    path: '/sm/search',
    name: 'SearchGlobalSM',
    component: SearchGlobal
  },
  {
    path: '/sm/views-rating',
    name: 'ViewsRating',
    component: ViewsRating
  },
  {
    path: '/sm/views-rating/:id',
    name: 'DetailsViewsRating',
    component: DetailsViewsRating
  },
  {
    path: '/sm/project/survey-complaint/:projectId',
    name: 'Survey Complaint',
    component: SurveyComplain
  },
  {
    path: '/sm/specific-search',
    name: 'Specific Search',
    component: SpecificSearch
  },
  {
    path: '/sm/monitoring-konsultan',
    name: 'Monitoring Konsultan',
    component: MonitoringKonsultan
  }
]
export default routesSM
