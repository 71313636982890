import axios from 'axios'

const state = () => ({
  sessionList: []
})

const getters = {
  sessionList: (state) => state.sessionList
}

const actions = {
  getListSession ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${payload.url}${process.env.VUE_APP_SESSION}`)
        .then(response => {
          commit('setListSession', response.data.data)
          resolve(response)
        })
        .catch(error => {
          console.error('Error:', error)
          reject(error)
        })
    })
  }
}

const mutations = {
  setListSession (state, data) {
    state.sessionList = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
